import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { NotFoundPage } from "./pages/404";
import Index from "./pages";
import Login from "./pages/login";
import MembershipPage from "./pages/membership";
import PaymentPage from "./pages/payment";
import PaymentDetailPage from "./pages/payment/[id]";
import IntegrationPage from "./pages/integration";
import SettingsPage from "./pages/settings";
import AdminIndexPage from "./pages/admin";
import AdminMembership from "./pages/admin/membership";
import SignupPage from "./pages/signup/[plan]";
import PasswordResetKeyPage from "./pages/password-reset/[key]";
import PasswordResetPage from "./pages/password-reset";
import SuccessPage from "./pages/success/[invoice]";
import AdminMemberPage from "./pages/admin/member/[id]";
import RedeemPage from "./pages/redeem";
import AdminCouponsPage from "./pages/admin/coupon";
import AdminCreateCouponsPage from "./pages/admin/coupon/create";
import AdminCouponDetailsPage from "./pages/admin/coupon/[code]";

function App() {
  return (
    <div className="w-full min-h-screen font-sans antialiased bg-gray-200">
      <BrowserRouter>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/membership">
            <MembershipPage />
          </Route>
          <Route path="/redeem">
            <RedeemPage />
          </Route>
          <Route path="/payment/:invoiceID">
            <PaymentDetailPage />
          </Route>
          <Route path="/success/:invoiceID">
            <SuccessPage />
          </Route>
          <Route exact path="/payment">
            <PaymentPage />
          </Route>
          <Route exact path="/integration">
            <IntegrationPage />
          </Route>
          <Route exact path="/settings">
            <SettingsPage />
          </Route>

          <Route path="/signup/:plan">
            <SignupPage />
          </Route>
          <Route exact path="/signup">
            <SignupPage />
          </Route>

          <Route path="/password-reset/:key">
            <PasswordResetKeyPage />
          </Route>
          <Route exact path="/password-reset">
            <PasswordResetPage />
          </Route>

          <Route exact path="/">
            <Index />
          </Route>

          {/* 
            Admin Pages
          */}
          <Route exact path="/admin">
            <AdminIndexPage />
          </Route>
          <Route exact path="/admin/membership">
            <AdminMembership />
          </Route>
          <Route exact path="/admin/voucher/create">
            <AdminCreateCouponsPage />
          </Route>
          <Route exact path="/admin/voucher">
            <AdminCouponsPage />
          </Route>
          <Route path="/admin/voucher/:code">
            <AdminCouponDetailsPage />
          </Route>
          <Route path="/admin/member/:id">
            <AdminMemberPage />
          </Route>
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
