import React from "react";
import {
  usePlansQuery,
  Plan,
  useUpdateMembershipMutation,
  PlanPrice
} from "@wa/graphql/generated";
import { capitalize, formatError } from "../../utils/string";
import classnames from "classnames";
import { useForm, useFieldArray } from "react-hook-form";
import { Fragment } from "react";
import AdminLayout from "../../components/Admin/AdminLayout";

type UpdatePlanProps = {
  plan: {
    id: string;
    name: string;
    description: string;
  } & {
    pricing: Omit<PlanPrice, "plan">[];
  };
};

interface FormData {
  name: string;
  pricing: {
    id: string;
    price: string;
    duration: string;
  }[];
  description: string;
}

const UpdatePlan: React.FC<UpdatePlanProps> = ({ plan }) => {
  const { id, name, pricing, description } = plan;

  const [update, { loading }] = useUpdateMembershipMutation();

  const { register, handleSubmit, control } = useForm<FormData>({
    defaultValues: {
      name: capitalize(name),
      pricing: pricing.map((p) => ({
        id: p.id,
        price: p.price.toString(),
        duration: p.duration.toString()
      })),
      description
    }
  });

  const { fields } = useFieldArray({
    control,
    name: "pricing"
  });

  const onSubmit = async (values: FormData) => {
    console.log(values);
    await update({
      variables: {
        id,
        name: values.name,
        // price: parseInt(values.price),
        pricing: values.pricing.map((p) => ({
          id: p.id,
          price: parseInt(p.price),
          duration: parseInt(p.duration)
        })),
        description: values.description
      }
    });
  };

  return (
    <form
      className="grid grid-cols-1 gap-6 px-6 py-4 border-b md:grid-cols-3 hover:bg-gray-100"
      key={id}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex flex-col">
        <span className="pb-1 text-sm font-medium">Nama</span>
        <input
          type="text"
          name="name"
          className="form-input"
          ref={register({
            required: true
          })}
        />
      </div>
      <div className="flex flex-col">
        <div className="grid grid-cols-2 gap-4">
          <span className="pb-1 text-sm font-medium">Durasi</span>
          <span className="pb-1 text-sm font-medium">Harga</span>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {fields.map((p, index) => {
            const isFree = parseInt(p.price) === 0;

            return (
              <Fragment key={p.id}>
                <input
                  type="number"
                  name={`pricing[${index}].duration`}
                  className={classnames("form-input", {
                    "bg-gray-200": isFree
                  })}
                  disabled={isFree}
                  ref={register({
                    required: true
                  })}
                />
                <input
                  type="number"
                  name={`pricing[${index}].price`}
                  className={classnames("form-input", {
                    "bg-gray-200": isFree
                  })}
                  disabled={isFree}
                  ref={register({
                    required: true
                  })}
                />
              </Fragment>
            );
          })}
        </div>
      </div>
      <div className="flex flex-col">
        <span className="pb-1 text-sm font-medium">Deskripsi</span>
        <textarea
          name="description"
          className="block w-full form-textarea"
          rows={3}
          placeholder=""
          ref={register()}
        ></textarea>
      </div>
      <div className="flex justify-end col-span-1 md:col-span-3">
        <button
          className={classnames("text-white font-medium rounded px-4 py-2", {
            "bg-indigo-600": !loading,
            "bg-indigo-400": loading
          })}
          type="submit"
          disabled={loading}
        >
          {loading ? "Loading..." : "Update"}
        </button>
      </div>
    </form>
  );
};

type Props = {};

const AdminMembership: React.FC<Props> = () => {
  const { data, loading, error } = usePlansQuery();

  return (
    <AdminLayout activeMenu="Membership">
      {loading && (
        <div className="flex items-center justify-center">Loading...</div>
      )}
      {error && (
        <div className="flex items-center justify-center">
          {formatError(error.message)}
        </div>
      )}
      {data && (
        <>
          <h2 className="px-6 py-4 text-lg font-medium">Daftar Membership</h2>
          <div className="pt-5 pb-3">
            {data?.plans?.map((plan) => (
              <UpdatePlan plan={plan} key={plan.id} />
            ))}
            <div className="px-6 pt-4 pb-1"></div>
          </div>
        </>
      )}
    </AdminLayout>
  );
};

export default AdminMembership;
