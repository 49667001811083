import React from 'react'
import classnames from "classnames";
import { forwardRef } from "react";
import { FieldError } from "react-hook-form";

type FormInputProps = {
  name: string;
  label: string;
  placeholder?: string;
  type: string;
  defaultValue?: string | number | string[];
  disabled?: boolean;
  error?: FieldError;
};

export const FormInput = forwardRef(
  (
    {
      label,
      type,
      placeholder = "",
      name,
      error,
      defaultValue,
      disabled = false
    }: FormInputProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <label className="block mb-4">
        <span className="text-sm font-medium text-gray-800">{label}</span>
        <input
          className={classnames(
            "form-input text-sm focus:bg-white mt-1 block w-full placeholder-gray-600",
            {
              "bg-fafafa": !disabled,
              "bg-gray-300 text-gray-600": disabled
            }
          )}
          placeholder={placeholder}
          type={type}
          name={name}
          ref={ref}
          defaultValue={defaultValue}
          disabled={disabled}
        />
        {error && (
          <span className="text-xs leading-none text-red-600">
            {error.message}
          </span>
        )}
      </label>
    );
  }
);
