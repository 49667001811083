import React from "react";
import { useMembersQuery } from "@wa/graphql/generated";
import { Link } from "react-router-dom";
import { useState } from "react";
import { id } from "date-fns/locale";
import { formatWithOptions } from "date-fns/fp";
import AdminLayout from "../../components/Admin/AdminLayout";
import { capitalize } from "../../utils/string";
import { SaveOutline } from "@graywolfai/react-heroicons";

const formatDate = formatWithOptions({ locale: id }, "d MMMM YYY");

const AdminIndexPage: React.FC = () => {
  const { data, loading, refetch, error } = useMembersQuery({
    fetchPolicy: "no-cache"
  });

  const [query, setQuery] = useState("");

  const handleSubmit = async () => {
    await refetch({
      query
    });
  };

  if (!data || loading) return <div className="min-h-screen bg-gray-100"></div>;

  return (
    <AdminLayout activeMenu="User">
      <div className="flex items-center justify-between px-6 py-4">
        <h2 className="text-lg font-medium">Active Member</h2>
        <a
          href="/admin/export"
          target="_blank"
          className="flex items-center space-x-2 text-sm font-medium text-blue-700"
        >
          <SaveOutline className="w-5 h-5" />
          <span>Export Users Data</span>
        </a>
      </div>
      <div className="px-4 pt-5 pb-3 mb-4 sm:px-6">
        <div>
          <form
            className="flex p-0"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <input
              type="text"
              className="w-full px-4 border border-gray-400 rounded-r-none form-input"
              placeholder="Cari User"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
            <button
              className="px-4 py-2 font-medium text-white bg-indigo-600 rounded-r"
              type="submit"
            >
              Search
            </button>
          </form>
        </div>
      </div>
      <ul className="py-4">
        {loading && (
          <div className="flex items-center justify-center">Loading...</div>
        )}
        {!loading && data?.members?.length === 0 && (
          <div className="flex items-center justify-center">
            Hasil pencarian kosong...
          </div>
        )}
        {!loading &&
          data?.members?.map((member) => (
            <li key={member.id}>
              <Link to={`/admin/member/${member.id}`}>
                <div className="grid grid-cols-2 px-6 py-3 border-b cursor-pointer hover:bg-gray-200">
                  <div className="flex flex-col items-start justify-center">
                    <div className="flex items-center">
                      <span>{member.fullname}</span>
                      <span className="px-2 py-px ml-3 text-sm font-medium text-blue-900 bg-blue-200 rounded-full">
                        {capitalize(member.membership.plan.name)}
                      </span>
                    </div>
                    <span className="text-sm text-gray-600">
                      {member.email}
                    </span>
                  </div>
                  <div className="flex flex-col items-start justify-center">
                    <span className="text-sm text-gray-600">
                      Terdaftar{" "}
                      <span className="font-medium text-gray-700">
                        {formatDate(new Date(member.createdAt))}
                      </span>
                    </span>
                    <span className="text-sm text-gray-600">
                      Berlaku sampai{" "}
                      <span className="font-medium text-gray-700">
                        {member.membership.expiredAt
                          ? formatDate(new Date(member.membership.expiredAt))
                          : "-"}
                      </span>
                    </span>
                  </div>
                </div>
              </Link>
            </li>
          ))}
      </ul>
    </AdminLayout>
  );
};

export default AdminIndexPage;
