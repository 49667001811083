import { useHistory, useParams } from "react-router";
import React from "react";
import {
  useDeleteUserMutation,
  useMemberQuery,
  useUpdateUserMembershipMutation
} from "@wa/graphql/generated";
import classnames from "classnames";
import { addMonths } from "date-fns";
import { formatWithOptions } from "date-fns/fp";
import { id as ID } from "date-fns/locale";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import AdminLayout from "../../../components/Admin/AdminLayout";
import { capitalize, formatError } from "../../../utils/string";
import { formatNumber } from "../../../utils/number";
import { InvoiceStatusLabel } from "../../../components/InvoiceStatusLabel";

const formatDate = formatWithOptions({ locale: ID }, "d MMMM YYY");

type Props = {
  id: string;
};

interface FormData {
  selectedPlan: string;
  planDuration: string;
}

const MemberDetailPage: React.FC<Props> = ({ id }) => {
  const history = useHistory();
  const { register, watch, handleSubmit, errors } = useForm<FormData>();
  const [hasSamePlan, setHasSamePlan] = useState(false);

  const { data, loading, error } = useMemberQuery({
    variables: {
      id
    }
  });

  const [updateMembership, { client: apolloClient }] =
    useUpdateUserMembershipMutation();

  const [deleteUser] = useDeleteUserMutation({
    onCompleted: (res) => {
      if (res.deleteUser) {
        history.push("/admin");
      }
    }
  });

  const handleMembershipChange = async (values: FormData) => {
    if (!data?.member?.id) throw Error("User id not found");

    await updateMembership({
      variables: {
        planId: values.selectedPlan,
        userId: data?.member.id,
        duration: parseInt(values.planDuration)
      }
    });

    apolloClient?.reFetchObservableQueries();
  };

  useEffect(() => {
    if (data) {
      const selected = watch("selectedPlan", data?.member?.membership.plan.id);

      setHasSamePlan(data?.member?.membership.plan.id === selected);
    }
  }, [data, watch("selectedPlan")]);

  return (
    <AdminLayout activeMenu="User">
      {loading && (
        <div className="flex items-center justify-center">Loading...</div>
      )}
      {error && (
        <div className="flex items-center justify-center">
          {formatError(error.message)}
        </div>
      )}
      {data?.member && (
        <>
          <div className="flex items-center px-6 py-4">
            <Link to="/admin">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="w-5 h-5 cursor-pointer fill-current"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
              </svg>
            </Link>
            <h2 className="pl-3 text-lg font-medium">Informasi User</h2>
          </div>

          <div className="grid grid-cols-3 px-4 py-5 pb-3 even:bg-gray-100 sm:px-6">
            <div className="col-span-1 font-medium text-gray-600">
              Nama Lengkap
            </div>
            <div className="col-span-2">{data.member.fullname}</div>
          </div>
          <div className="grid grid-cols-3 px-4 py-5 pb-3 even:bg-gray-100 sm:px-6">
            <div className="col-span-1 font-medium text-gray-600">
              Alamat Email
            </div>
            <div className="flex items-center justify-between col-span-2">
              <p>{data.member.email}</p>
              <button
                className="px-2 py-1 text-sm text-red-600 border border-red-100 rounded hover:bg-red-100"
                onClick={() => {
                  if (
                    window.confirm(
                      `Are you sure want to delete "${data.member?.fullname}" account?`
                    )
                  ) {
                    deleteUser({
                      variables: {
                        userId: data.member?.id!!
                      }
                    });
                  }
                }}
              >
                Delete Account
              </button>
            </div>
          </div>
          <div className="grid grid-cols-3 px-4 py-5 pb-3 even:bg-gray-100 sm:px-6">
            <div className="col-span-1 font-medium text-gray-600">No HP</div>
            <div className="col-span-2">{data.member.phone}</div>
          </div>
          <div className="grid grid-cols-3 px-4 py-5 pb-3 even:bg-gray-100 sm:px-6">
            <div className="col-span-1 font-medium text-gray-600">
              Membership
            </div>
            <div className="col-span-2">
              {capitalize(data.member.membership.plan.name)}
            </div>
          </div>
          <div className="grid grid-cols-3 px-4 py-5 pb-3 even:bg-gray-100 sm:px-6">
            <div className="col-span-1 font-medium text-gray-600">
              Tanggal Berakhir
            </div>
            <div className="col-span-2">
              {data.member.membership.expiredAt
                ? formatDate(new Date(data.member.membership.expiredAt))
                : "-"}
            </div>
          </div>
          <div className="grid grid-cols-3 px-4 py-5 pb-3 even:bg-gray-100 sm:px-6">
            <div className="col-span-1 font-medium text-gray-600">
              Tanggal Terdaftar
            </div>
            <div className="col-span-2">
              {formatDate(new Date(data.member.createdAt))}
            </div>
          </div>

          <form
            className="px-6 pt-12 pb-4"
            onSubmit={handleSubmit(handleMembershipChange)}
          >
            <h2 className="pb-3 text-lg font-medium">Ganti Membership</h2>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div>
                <span className="text-sm font-medium">Paket</span>
                <select
                  className="block w-full form-select"
                  name="selectedPlan"
                  ref={register({ required: true })}
                  defaultValue={data.member.membership.plan.id}
                >
                  {data?.plans?.map((plan) => (
                    <option value={plan.id} key={plan.id}>
                      {capitalize(plan.name)}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <span className="text-sm font-medium">Durasi</span>
                <input
                  type="number"
                  className={classnames("form-input w-full", {
                    "bg-gray-200": hasSamePlan
                  })}
                  name="planDuration"
                  ref={register({ required: true, min: 1, max: 12 })}
                  defaultValue={1}
                  disabled={hasSamePlan}
                />
              </div>
            </div>
            <div className="py-4">
              {errors?.planDuration && (
                <div className="px-3 py-2 mb-2 text-sm text-red-600 bg-red-200 rounded">
                  Durasi hanya bisa untuk 1 sampai 12 bulan
                </div>
              )}
              {!hasSamePlan && (
                <div className="px-3 py-2 mb-2 text-sm text-gray-700 bg-gray-200 rounded">
                  Membership akan berlaku hingga{" "}
                  <span className="text-gray-900">
                    {formatDate(
                      addMonths(
                        new Date(),
                        parseInt(watch("planDuration", "1"))
                      )
                    )}
                  </span>
                </div>
              )}
              <button
                className={classnames(
                  "text-white font-medium rounded px-4 py-2 mt-2",
                  {
                    "bg-indigo-600": !hasSamePlan,
                    "bg-indigo-400 cursor-not-allowed": hasSamePlan
                  }
                )}
                type="submit"
                disabled={hasSamePlan}
              >
                Update
              </button>
            </div>
          </form>

          <div className="px-6 pt-12 pb-4">
            <h2 className="text-lg font-medium">
              {data.member.invoices.length} Invoices
            </h2>
          </div>

          <ul>
            {[...data.member.invoices].reverse().map((invoice) => (
              <li
                className="grid grid-cols-2 px-6 py-3 border-b hover:bg-gray-200"
                key={invoice.id}
              >
                <div className="flex items-center">
                  <span>Rp {formatNumber(invoice.finalAmount)}</span>
                  <span className="ml-3 text-sm">
                    <InvoiceStatusLabel status={invoice.status} />
                  </span>
                </div>
                <div className="flex flex-col items-start justify-center">
                  <span className="text-sm text-gray-600">
                    Plan :{" "}
                    <span className="font-medium text-gray-700">
                      {capitalize(invoice.plan.name)}
                    </span>
                  </span>
                  <span className="pt-2 text-sm text-gray-600">
                    Dibuat{" "}
                    <span className="font-medium text-gray-700">
                      {formatWithOptions(
                        { locale: ID },
                        "d MMMM YYY"
                      )(new Date(invoice.createdAt))}
                    </span>
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
    </AdminLayout>
  );
};

const AdminMemberPage = () => {
  const { id } = useParams<{
    id: string;
  }>();

  return <MemberDetailPage id={id} />;
};

export default AdminMemberPage;
