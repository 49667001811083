export const capitalize = (text: string) => {
  return text
    .split(" ")
    .map((t: string) => t.charAt(0).toUpperCase() + t.toLowerCase().slice(1))
    .join(" ");
};

export const formatError = (text: string) => {
  if (!text.includes(":")) return text;
  const [, ...message] = text.split(":");
  return message.join(" ");
};
