import { Plan } from "@prisma/client";

const plans: {
  [key: string]: number;
} = {
  free: 0,
  marketer: 1,
  developer: 2
};

export function isEligible(
  plan: Plan | Pick<Plan, "name">,
  minimumPlan: "free" | "marketer" | "developer"
) {
  const { name } = plan;

  if (plans[name] === undefined)
    throw Error(`Plan with name ${name} not found`);

  return plans[name] >= plans[minimumPlan];
}

export function hasPremiumPlan(plan?: Plan | Pick<Plan, "name">) {
  if (!plan) return false;

  return plan.name === "marketer" || plan.name === "developer";
}
