import React from "react";
import { useMutation } from "@apollo/client";
import {
  CogSolid,
  CreditCardSolid,
  CubeTransparentSolid,
  DownloadSolid,
  GiftSolid,
  HomeSolid,
  LogoutSolid,
  SupportOutline,
  SupportSolid
} from "@graywolfai/react-heroicons";
import classnames from "classnames";
import gql from "graphql-tag";
import { cloneElement } from "react";
import { Link } from "react-router-dom";
import { useSidebar } from "./hooks/useSidebar";
import Separator from "./Separator";

type SidebarItemProp = {
  title: string;
  href?: string;
  onClick?: Function;
  children: JSX.Element;
};

const SidebarItem: React.FC<SidebarItemProp> = ({
  title,
  href,
  onClick,
  children
}) => {
  const { state } = useSidebar();
  const isActive = state.active === title;

  if (href?.startsWith("http")) {
    return (
      <a
        href={href}
        className="flex items-center px-4 py-3 mx-4 mb-1 text-sm font-medium text-gray-400 rounded-md cursor-pointer hover:bg-gray-800 hover:text-gray-200"
        target="_blank"
      >
        {cloneElement(children, { className: "w-6 h-6 fill-current" })}
        <span className="pl-6">{title}</span>
      </a>
    );
  }

  return (
    <Link
      to={href ?? "#"}
      onClick={() => {
        onClick && onClick();
      }}
      className={classnames(
        "flex items-center text-sm font-medium rounded-md hover:bg-gray-800 hover:text-gray-200 cursor-pointer px-4 py-3 mx-4 mb-1",
        {
          "text-gray-400": !isActive,
          "text-white bg-gray-700": isActive
        }
      )}
    >
      {cloneElement(children, { className: "w-6 h-6 fill-current" })}
      <span className="pl-6">{title}</span>
    </Link>
  );
};

const LOGOUT = gql`
  mutation logout {
    logout
  }
`;

const Sidebar: React.FC = () => {
  const { state, dispatch } = useSidebar();
  const { sidebarOpen } = state;
  const [logout, { client }] = useMutation(LOGOUT);

  return (
    <>
      {state.sidebarOpen && (
        <button
          className="fixed z-10 w-full h-full bg-black opacity-25"
          onClick={() => dispatch({ type: "close" })}
        ></button>
      )}
      <div
        className={classnames(
          "absolute lg:static lg:block w-64 h-screen bg-gray-900 shadow-lg transform transition-transform duration-100 ease-in lg:transform-none overflow-auto z-50",
          {
            "-translate-x-full": !sidebarOpen
          }
        )}
      >
        <div className="flex items-center justify-center py-4">
          <img className="w-8 h-8" src="/icon.png" alt="Whatsapp CS Logo" />
          <h1 className="px-3 text-xl text-white">Member Area</h1>
        </div>
        <div className="flex flex-col">
          <SidebarItem href="/" title="Dashboard">
            <HomeSolid />
          </SidebarItem>
          <SidebarItem href="/membership" title="Membership">
            <GiftSolid />
          </SidebarItem>
          <SidebarItem href="/payment" title="Payment">
            <CreditCardSolid />
          </SidebarItem>
          <SidebarItem href="https://cutt.ly/memdown" title="Install">
            <DownloadSolid />
          </SidebarItem>
          <Separator borderColor="border-gray-800" />
          <SidebarItem href="/integration" title="Integration">
            <CubeTransparentSolid />
          </SidebarItem>
          <SidebarItem href="/settings" title="Settings">
            <CogSolid />
          </SidebarItem>
          <SidebarItem href="https://cutt.ly/memsup" title="Support">
            <SupportSolid />
          </SidebarItem>
          <SidebarItem
            onClick={() => {
              const fn = async () => {
                await logout();
                await client?.resetStore();
                window.location.href = "/login";
              };
              fn();
            }}
            title="Logout"
          >
            <LogoutSolid />
          </SidebarItem>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
