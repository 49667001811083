import ReactDOM from "react-dom";
import React from "react";
import "./index.css";
import App from "./App";
import { StrictMode } from "react";
import { client } from "./apollo/client";
import { ApolloProvider } from "@apollo/client";

ReactDOM.render(
  <StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </StrictMode>,
  document.getElementById("root")
);
