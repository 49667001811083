import React from "react";
import {
  InvoiceStatus,
  PaymentMethod,
  useCheckInvoiceMutation,
  useInvoiceQuery
} from "@wa/graphql/generated";
import classnames from "classnames";
import { isAfter, parseISO } from "date-fns";
import { formatWithOptions } from "date-fns/fp";
import { id } from "date-fns/locale";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router";
import Card from "../../components/Card";
import Header from "../../components/Header";
import { InfoRow } from "../../components/InfoRow";
import { InvoiceStatusLabel } from "../../components/InvoiceStatusLabel";
import Layout from "../../components/Layout";
import Separator from "../../components/Separator";
import { formatEnum } from "../../utils/enum";
import { formatNumber } from "../../utils/number";
import { capitalize } from "../../utils/string";

export const bankOptions = [
  {
    name: "BCA",
    number: "1220830493",
    image: "bca.png"
  }
];

const formatBankNumber = (number: string) => {
  let formatted = "";

  for (let i = number.length - 1; i >= 0; i--) {
    if (i > 2 && (number.length - i) % 4 === 0)
      formatted = "-" + number[i] + formatted;
    else formatted = number[i] + formatted;
  }

  return formatted;
};

const PaymentDetailPage = () => {
  let { invoiceID } = useParams<{
    invoiceID: string;
  }>();

  const { data, error, refetch } = useInvoiceQuery({
    variables: {
      id: invoiceID
    }
  });

  const [check, { loading }] = useCheckInvoiceMutation();

  const [isCheckable, setCheckable] = useState(false);

  useEffect(() => {
    if (data?.invoice?.lastChecked) {
      const lastChecked = data.invoice?.lastChecked || new Date();

      if (isAfter(new Date(), parseISO(lastChecked))) setCheckable(true);
      else setCheckable(false);
    } else {
      setCheckable(true);
    }
  }, [data]);

  useEffect(() => {
    if (error?.message.match(/Access Forbidden/i)) {
      window.location.href = "/payment";
    }
  }, [error]);

  useEffect(() => {
    console.log("Registering interval");

    const interval = setInterval(() => {
      console.log("Refreshing invoice");
      refetch();
    }, 30 * 1000);

    return () => {
      console.log("Clearing interval");
      clearInterval(interval);
    };
  }, []);

  const checkInvoice = async (id: string) => {
    await check({
      variables: {
        id
      }
    });
    setCheckable(false);
    refetch;
  };

  return (
    <Layout active="Payment">
      {/* <Head>
        <title>Detail Invoice</title>
      </Head> */}
      <Header title="Invoice" />
      <div className="grid max-w-xl gap-4 px-4 py-4 mx-auto">
        {data?.invoice ? (
          <Card title="Payment Invoice" className="overflow-hidden">
            <div className="grid grid-cols-1 gap-2 px-4 pt-2 pb-6 md:grid-cols-5 md:gap-4">
              <InfoRow title="ID" content={data?.invoice.id || <Skeleton />} />
              <InfoRow
                title="Product"
                content={
                  (data &&
                    `WhatsApp CS (${capitalize(data?.invoice.plan.name)})`) || (
                    <Skeleton />
                  )
                }
              />
              <InfoRow
                title="Base Price"
                content={
                  (data &&
                    `Rp ${formatNumber(data.invoice.originalAmount)}`) || (
                    <Skeleton />
                  )
                }
              />

              <InfoRow
                title="Confirmation Amount"
                content={
                  (data &&
                    `Rp ${formatNumber(
                      data.invoice.finalAmount -
                        (data.invoice.originalAmount)
                    )}`) || <Skeleton />
                }
              />
              <InfoRow
                title="Total Price"
                content={
                  (data && `Rp ${formatNumber(data.invoice.finalAmount)}`) || (
                    <Skeleton />
                  )
                }
              />
              <InfoRow
                title="Payment Method"
                content={
                  (data &&
                    (data.invoice.method
                      ? capitalize(formatEnum(data.invoice.method))
                      : "-")) || <Skeleton />
                }
              />
              <InfoRow title="Status">
                {(data && (
                  <InvoiceStatusLabel status={data.invoice.status} />
                )) || <Skeleton />}
              </InfoRow>
              <InfoRow
                title="Date"
                content={
                  (data &&
                    formatWithOptions(
                      { locale: id },
                      "d MMMM YYY"
                    )(new Date(data.invoice.createdAt))) || <Skeleton />
                }
              />
            </div>
            {data &&
              data.invoice.method === PaymentMethod.BankTransfer &&
              data.invoice.status === InvoiceStatus.Pending && (
                <>
                  <Separator />
                  <div className="px-6 py-4 text-xl font-medium text-gray-900">
                    Cara Pembayaran
                  </div>
                  <div className="px-6 pb-4">
                    <p className="text-gray-800">
                      Silahkan transfer{" "}
                      <span className="inline-block px-2 py-1 font-bold text-gray-900 bg-blue-200 rounded">
                        Rp {formatNumber(data.invoice.finalAmount)}
                      </span>{" "}
                      sebelum{" "}
                      <span className="font-semibold">
                        {formatWithOptions(
                          { locale: id },
                          "d MMMM YYY HH:mm"
                        )(new Date(data.invoice.expiredAt))}
                      </span>{" "}
                      ke salah satu <span className="font-bold">Rekening</span>{" "}
                      berikut:
                    </p>
                    <div className="grid grid-cols-2 gap-2 py-5">
                      {bankOptions.map((bank) => (
                        <div
                          className="col-span-1 px-4 py-6 border border-gray-300 rounded"
                          key={bank.name}
                        >
                          <img
                            className="object-contain w-32 h-10"
                            src={`/logo/${bank.image}`}
                            alt={bank.name}
                          />
                          <span className="py-3 text-lg font-semibold text-gray-900 break-all">
                            {formatBankNumber(bank.number)}
                          </span>
                          <span className="block text-sm text-gray-800">
                            atas nama: Dwi Cahyo
                          </span>
                        </div>
                      ))}
                    </div>
                    <div className="p-3 text-gray-800 bg-yellow-100 border border-yellow-500 rounded">
                      <p>
                        Mohon transfer sesuai jumlah tagihan sebesar{" "}
                        <span className="font-semibold">
                          Rp {formatNumber(data.invoice.finalAmount)}
                        </span>{" "}
                        Karena sistem kami tidak bisa mendeteksi pembayaran Anda
                        bila jumlahnya tidak sesuai.
                      </p>
                    </div>
                    <div className="py-4">
                      <button
                        className={classnames(
                          "w-full text-white font-semibold rounded shadow px-4 py-2 hidden",
                          {
                            "bg-blue-600": !loading && isCheckable,
                            "bg-gray-600": loading || !isCheckable
                          }
                        )}
                        disabled={loading || !isCheckable}
                        onClick={() => checkInvoice(data?.invoice?.id!)}
                        type="submit"
                      >
                        {isCheckable
                          ? "Saya sudah transfer"
                          : "Sedang memproses..."}
                      </button>
                      <div className="px-3 py-2 mt-4 text-gray-800 bg-yellow-100 border border-yellow-500 rounded">
                        <p>
                           Silahkan hubungi CS untuk konfirmasi transfer
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            {data &&
              data.invoice.method === PaymentMethod.Paypal &&
              data.invoice.status === InvoiceStatus.Pending && (
                <>
                  <Separator />
                  <div className="px-6 py-4 text-xl font-medium text-gray-900">
                    Pay using PayPal
                  </div>
                  <div className="px-6 pb-4">
                    <a
                      className="block w-full px-4 py-2 font-semibold text-center text-white bg-blue-600 rounded shadow"
                      href={`/paypal/redirect?InvoiceID=${data.invoice.id}`}
                    >
                      Pay Now
                    </a>
                  </div>
                </>
              )}
          </Card>
        ) : null}
      </div>
    </Layout>
  );
};

export default PaymentDetailPage;
