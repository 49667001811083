import React from 'react'
import { useSidebar } from "./hooks/useSidebar";
import { MenuSolid } from "@graywolfai/react-heroicons";
import useUser from "./hooks/useUser";
import Skeleton from "react-loading-skeleton";

const Header: React.FC<{ title: string }> = ({ title }) => {
  const { dispatch } = useSidebar();

  const { data } = useUser();

  return (
    <div className="flex items-center justify-between px-4 py-4 bg-white border-b border-gray-300 md:px-6">
      <div className="flex items-center text-lg font-semibold text-gray-800">
        <button
          className="flex items-center justify-center lg:hidden focus:outline-none"
          onClick={() => dispatch({ type: "toggle" })}
        >
          <MenuSolid className="w-6 h-6 fill-current" />
        </button>
        <span className="pl-4 lg:pl-0">{title}</span>
      </div>
      <div className="flex items-center">
        <span className="text-sm font-medium text-gray-700">
          {data?.me?.fullname || <Skeleton />}
        </span>
        {/* <img
          className="object-cover w-8 h-8 rounded-full"
          src="https://randomuser.me/api/portraits/men/81.jpg"
          alt="test"
        /> */}
      </div>
    </div>
  );
};

export default Header;
