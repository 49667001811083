import React from 'react'
import { useResetPasswordMutation } from "@wa/graphql/generated";
import classnames from "classnames";
import { useForm } from "react-hook-form";
import { Redirect, useParams } from "react-router";
import * as yup from "yup";
import Separator from "../../components/Separator";
import { formatError } from "../../utils/string";

interface FormData {
  newPassword: string;
  confirmNewPassword: string;
}

const PasswordResetKeyPage = () => {
  const { key } = useParams<{
    key?: string;
  }>();
  const passwordResetKey = key;

  const errorMessages = {
    password: {
      required: "Password cannot be empty",
      min: "Password must be more than 6 characters",
      match: "Password is not match"
    }
  };

  const { handleSubmit, errors, register } = useForm<FormData>({
    validationSchema: yup.object().shape({
      newPassword: yup.string()
        .min(6, errorMessages.password.min)
        .required(errorMessages.password.required)
        .oneOf([yup.ref("confirmNewPassword")], errorMessages.password.match),
      confirmNewPassword: yup.string()
        .min(6, errorMessages.password.min)
        .required(errorMessages.password.required)
        .oneOf([yup.ref("newPassword")], errorMessages.password.match)
    })
  });

  const [reset, { data, loading, error }] = useResetPasswordMutation({
    onError: (error) => console.log(error)
  });

  if (!passwordResetKey) return <Redirect to={"/password-reset"} />;

  const onSubmit = async (values: FormData) => {
    await reset({
      variables: {
        key: passwordResetKey,
        newPassword: values.newPassword
      }
    });
  };

  return (
    <div className="flex items-center min-h-screen antialiased bg-gray-200">
      {/* <Head>
        <title>Reset your password</title>
      </Head> */}
      <div className="flex flex-col items-center justify-center max-w-xl px-2 mx-auto w-84">
        <div className="flex items-center justify-center py-4">
          <img className="w-8 h-8" src="/icon.png" alt="Whatsapp CS Logo" />
          <h1 className="pl-2 text-xl font-semibold">WhatsApp CS</h1>
        </div>
        <form
          className="flex flex-col w-full overflow-hidden bg-white rounded-lg shadow-md"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h3 className="px-5 pt-4 text-xl font-semibold text-gray-900">
            Reset Password
          </h3>
          <Separator />

          {(data && (
            <div className="px-4 py-3">
              <div className="px-3 py-2 mb-6 text-center text-green-700 bg-green-200 rounded">
                Password successfully changed
              </div>
              <a
                href="/login"
                className="block w-full px-4 py-2 font-semibold text-center text-white bg-blue-600 rounded shadow"
              >
                Login to Dashboard
              </a>
            </div>
          )) ?? (
            <>
              {error && (
                <span className="px-3 py-2 mx-5 mb-3 text-sm text-red-700 bg-red-200 rounded">
                  {formatError(error.message)}
                </span>
              )}
              <div className="grid gap-4 px-5 pb-4">
                <label className="block">
                  <span className="text-sm font-medium text-gray-700">
                    Insert your new password
                  </span>
                  <input
                    className="block w-full mt-1 form-input"
                    placeholder="*********"
                    type="password"
                    name="newPassword"
                    required
                    ref={register({ required: true })}
                  />
                  {errors.newPassword && (
                    <span className="text-sm text-red-700">
                      {errors.newPassword?.message}
                    </span>
                  )}
                </label>
                <label className="block">
                  <span className="text-sm font-medium text-gray-700">
                    Confirm your new password
                  </span>
                  <input
                    className="block w-full mt-1 form-input"
                    placeholder="*********"
                    type="password"
                    name="confirmNewPassword"
                    required
                    ref={register({ required: true })}
                  />
                  {errors.confirmNewPassword && (
                    <span className="text-sm text-red-700">
                      {errors.confirmNewPassword?.message}
                    </span>
                  )}
                </label>
                <button
                  className={classnames(
                    "w-full text-white font-semibold rounded shadow px-4 py-2",
                    {
                      "bg-blue-600": !loading,
                      "bg-gray-600": loading
                    }
                  )}
                  disabled={loading}
                  type="submit"
                >
                  Update Password
                </button>
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default PasswordResetKeyPage;
