import React from 'react'
import classnames from "classnames";
import { InvoiceStatus } from "@wa/graphql/generated";
import { capitalize } from "../utils/string";

export const InvoiceStatusLabel: React.FC<{ status: InvoiceStatus }> = ({
  status
}) => {
  return (
    <span
      className={classnames("font-semibold  rounded-full px-3 py-1", {
        "text-yellow-900 bg-yellow-200": status === InvoiceStatus.Pending,
        "text-green-900 bg-green-200": status === InvoiceStatus.Paid,
        "text-gray-900 bg-gray-200": status === InvoiceStatus.Cancelled
      })}
    >
      {capitalize(status)}
    </span>
  );
};
