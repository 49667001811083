import React from "react";
import { hasPremiumPlan } from "@wa/extension/src/utils/Membership";
import { usePlansQuery } from "@wa/graphql/generated";
import classnames from "classnames";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import {
  PricingCard,
  PricingFeatureListItem
} from "../../components/PricingCard";
import { Link } from "react-router-dom";

const FeatureList = [
  {
    name: "Contact Notes",
    available: [1, 1, 1]
  },
  {
    name: "Auto Reply",
    available: [1, 1, 1]
  },
  {
    name: "Bulk Message",
    available: [0, 1, 1]
  },
  {
    name: "Invoice Generator",
    available: [0, 1, 1]
  },
  {
    name: "Miscellaneous Tools",
    available: [0, 1, 1]
  },
  {
    name: "API Integration",
    available: [0, 0, 1]
  }
];

const MembershipPage = () => {
  const { data } = usePlansQuery();

  const renderFooter = (name: string) => {
    if (!data?.me?.membership.plan.name) return <></>;

    const planName = data.me.membership.plan.name.toLowerCase();
    const hasPremium = hasPremiumPlan(data?.me.membership.plan!);
    console.log("hasPremium", hasPremium);
    name = name.toLowerCase();

    return (
      <>
        <div className="pb-3">
          <div className="border-b border-gray-300"></div>
        </div>
        <div className="px-4 pb-3">
          <a
            href={planName !== name && !hasPremium ? `/signup/${name}` : "#!"}
            className={classnames(
              "block text-center font-semibold rounded py-2",
              {
                "bg-gray-500 text-gray-100 cursor-not-allowed":
                  planName === name || hasPremium,
                "bg-blue-600 text-blue-100": planName !== name && !hasPremium
              }
            )}
          >
            {planName === name ? "Active Plan" : "Change Plan"}
          </a>
        </div>
      </>
    );
  };

  return (
    <Layout active="Membership">
      {/* <Head>
        <title>Plans</title>
      </Head> */}
      <Header title="Plans" />

      <p className="px-2 pt-6 space-x-2 font-medium text-center text-gray-800 md:px-6">
        <span>Have a Membership Voucher?</span>
        <Link to="/redeem" className="font-semibold text-blue-600">Redeem here</Link>
      </p>
      <div className="flex items-center justify-center max-w-5xl px-2 mx-auto md:px-6">
        {data && (
          <div className="grid w-full grid-cols-1 gap-4 pt-3 pb-8 md:grid-cols-3">
            {data?.plans?.map((plan, index) => (
              <PricingCard
                name={plan.name}
                price={plan.pricing[0].price}
                key={plan.id}
                description={plan.description}
                renderFooter={(name) => renderFooter(name)}
              >
                {FeatureList.map((feature) => (
                  <PricingFeatureListItem
                    description={feature.name}
                    available={Boolean(feature.available[index])}
                  />
                ))}
              </PricingCard>
            ))}
            {hasPremiumPlan(data?.me?.membership.plan) && (
              <div className="col-span-1 px-3 py-2 text-gray-800 bg-yellow-100 border border-yellow-500 rounded md:col-span-3">
                <p>
                  You currently have a paid membership, to change your
                  membership please wait for the active period or contact
                  customer service
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default MembershipPage;
