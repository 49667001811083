import React from "react";
import { useQuery, gql } from "@apollo/client";
import {
  PaymentMethod,
  Plan,
  useBuyPlanSignedInMutation,
  useCheckEmailMutation,
  User,
  useSignupWithPlanMutation
} from "@wa/graphql/generated";
import classnames from "classnames";
import { debounce } from "debounce";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import { object, ObjectSchema, string } from "yup";
import { CardBase } from "../../components/Card";
import { FormInput } from "../../components/FormInput";
import Separator from "../../components/Separator";
import { formatNumber } from "../../utils/number";
import { capitalize } from "../../utils/string";
import { bankOptions } from "../payment/[id]";

type FormData = {
  fullname: string;
  email: string;
  phone: string;
  pricingID: string;
  password: string;
};

const errorMessages = {
  fullname: {
    required: "Full name cannot be empty"
  },
  email: {
    required: "Email address cannot be empty",
    email: "Please use valid email address",
    exists: "Email already registered, please use another email"
  },
  phone: {
    required: "Phone number cannot be empty",
    number: "Please use valid phone number"
  },
  pricingID: {
    required: "Please select membership duration"
  },
  password: {
    required: "Password cannot be empty",
    min: "Password must be longer than 6 characters"
  }
};

const e = errorMessages;

interface Props {
  plans: Plan[];
  user?: User;
  plan?: string;
}

export const SignupForm = (props: Props) => {
  const { user, plans, plan: planQuery } = props;

  const isLoggedIn = Boolean(user);

  const planIndex = planQuery
    ? plans.findIndex((p) => p.name === planQuery.toLowerCase())
    : -1;

  const selectedPlan = planIndex !== -1 ? planIndex : 0;

  const defaultValues = {
    fullname: "",
    email: "",
    password: "",
    phone: ""
  };

  const [activePlan, setActivePlan] = useState<Plan>(plans[selectedPlan]);

  const [activePaymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    PaymentMethod.BankTransfer
  );

  const [totalPrice, setTotalPrice] = useState(
    plans[selectedPlan].pricing[0].price
  );

  const getPlan = (id: string) => {
    return plans.find((p) => p.id === id);
  };

  const [signup, { error: signupError }] = useSignupWithPlanMutation();
  const [buy, { error: buyError }] = useBuyPlanSignedInMutation();
  const [checkEmail] = useCheckEmailMutation();

  const validateEmail = debounce(
    async (email: string): Promise<boolean> => {
      const abortController = new AbortController();
      const { data } = await checkEmail({
        variables: {
          email
        },
        context: {
          fetchOptions: {
            signal: abortController.signal
          }
        }
      });
      return data?.checkEmail || false;
    },
    500,
    true
  );

  // @ts-ignore
  let validationSchema: ObjectSchema = isLoggedIn
    ? object().shape({
        pricingID: string().required()
      })
    : object().shape({
        fullname: string().required(e.fullname.required),
        email: string()
          .email(e.email.email)
          // @ts-ignore
          .test("exists", e.email.exists, validateEmail)
          .required(e.email.required),
        phone: string().required(e.phone.required),
        pricingID: string().required(),
        password: string().min(6, e.password.min).required(e.password.required)
      });

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    errors
  } = useForm<FormData>({
    defaultValues,
    validationSchema
  });

  const pricingID = watch("pricingID", activePlan.pricing[0].id) as string;

  useEffect(() => {
    const newPrice =
      activePlan.pricing.find((p) => p.id === getValues()["pricingID"])
        ?.price ?? 0;
    // console.log("Calculating Total Price", newPrice);
    setTotalPrice(newPrice);
  }, [activePlan, pricingID]);

  const [isLoading, setLoading] = useState(false);

  const onSubmit = async (values: FormData) => {
    try {
      setLoading(true);

      const { fullname, email, password, phone, pricingID } = values;

      let result;

      if (isLoggedIn) {
        const { data } = await buy({
          variables: {
            method: activePaymentMethod,
            couponID: undefined,
            pricingID
          }
        });
        result = data?.buyPlanSignedIn;
      } else {
        const { data } = await signup({
          variables: {
            fullname,
            email,
            password,
            phone,
            method: activePaymentMethod,
            couponID: undefined,
            pricingID
          }
        });
        result = data?.signupWithPlan;
      }

      if (result?.redirectURL) {
        window.location.href = result.redirectURL;
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const hasPaidMembership = ["marketer", "developer"].includes(
    user?.membership?.plan?.name ?? ""
  );

  return (
    <div className="max-w-5xl mx-auto">
      {/* <Head>
        <title>Informasi Pembelian</title>
      </Head> */}
      <div className="flex px-4 pt-8 md:px-8">
        <a href="/" className="flex items-center flex-shrink-0">
          <img className="w-8 h-8" src="/icon.png" alt="Whatsapp CS Logo" />
          <h1 className="pl-2 text-xl font-semibold">WhatsApp CS</h1>
        </a>
      </div>
      <form
        className="grid grid-cols-1 gap-6 px-4 py-6 lg:grid-cols-8 md:px-8"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="col-span-1 lg:col-span-5">
          <CardBase className="flex flex-col">
            <div className="px-6 py-4 text-xl font-medium text-gray-900">
              Purchase Membership
            </div>
            <div className="px-6 pb-3">
              <span className="text-sm font-medium text-gray-800">
                Select Plan
              </span>
              <div className="grid grid-cols-1 gap-3 py-2 md:grid-cols-3">
                {plans.map((plan) => (
                  <button
                    className={classnames(
                      "text-left rounded focus:outline-none transition-colors duration-100 ease-in p-4",
                      {
                        "text-blue-800 bg-blue-100 border-2 border-blue-700":
                          activePlan.id === plan.id,
                        "text-gray-900 border-2 border-gray-300":
                          activePlan.id !== plan.id
                      }
                    )}
                    key={plan.id}
                    aria-label={`Membership ${getPlan(plan.id)?.name}`}
                    onClick={() => setActivePlan(getPlan(plan.id)!)}
                    type="button"
                  >
                    <div className="flex items-center">
                      <h3 className="text-sm font-semibold leading-tight tracking-wider uppercase">
                        {plan.name}
                      </h3>
                    </div>
                    <div className="flex flex-col items-end pt-6">
                      <span className="flex items-center font-bold leading-none">
                        <span className="text-sm">Rp</span>
                        <span className="pl-1 text-lg">
                          {formatNumber(plan.pricing[0].price)}
                        </span>
                      </span>
                      <span className="pl-1 text-sm leading-none">/ bulan</span>
                    </div>
                  </button>
                ))}
              </div>
              <span className="block mb-4 text-sm text-gray-700">
                To see plans features detail go{" "}
                <a href="/plans" className="font-medium text-blue-500">
                  here
                </a>
              </span>

              <label
                className={classnames("mb-4", {
                  hidden: activePlan.id === plans[0].id,
                  block: activePlan.id !== plans[0].id
                })}
              >
                <span className="text-sm font-medium text-gray-800">
                  Choose Duration
                </span>
                <select
                  className="block w-full mt-1 form-select"
                  name="pricingID"
                  ref={register({ required: true })}
                >
                  {activePlan.pricing.map(({ id, duration, price }) => (
                    <option value={id} key={id}>
                      {duration} Bulan (Rp {formatNumber(price)})
                    </option>
                  ))}
                </select>
              </label>

              <label
                className={classnames("mb-4", {
                  hidden: activePlan.id === plans[0].id,
                  block: activePlan.id !== plans[0].id
                })}
              >
                <span className="text-sm font-medium text-gray-800">
                  Choose Payment Method
                </span>

                <div className="flex flex-col">
                  <button
                    className={classnames(
                      "rounded focus:outline-none transition-colors duration-100 ease-in px-4 py-6 mt-2",
                      {
                        "text-blue-800 bg-blue-100 border-2 border-blue-700":
                          activePaymentMethod === PaymentMethod.BankTransfer,
                        "text-gray-900 border-2 border-gray-300":
                          activePaymentMethod !== PaymentMethod.BankTransfer
                      }
                    )}
                    type="button"
                    aria-label="Bank Transfer"
                    onClick={() => setPaymentMethod(PaymentMethod.BankTransfer)}
                  >
                    <p className="text-lg font-medium text-left">
                      Bank Transfer
                    </p>
                    <div className="flex justify-end">
                      {bankOptions.map((bank) => (
                        <img
                          className="object-contain w-16 h-5 ml-3"
                          src={`/logo/${bank.image}`}
                          alt={bank.name}
                          key={bank.name}
                        />
                      ))}
                    </div>
                  </button>

                  <button
                    className={classnames(
                      "rounded focus:outline-none transition-colors duration-100 ease-in px-4 py-6 mt-2",
                      {
                        "text-blue-800 bg-blue-100 border-2 border-blue-700":
                          activePaymentMethod === PaymentMethod.Paypal,
                        "text-gray-900 border-2 border-gray-300":
                          activePaymentMethod !== PaymentMethod.Paypal
                      }
                    )}
                    type="button"
                    aria-label="Paypal or Credit Card"
                    onClick={() => setPaymentMethod(PaymentMethod.Paypal)}
                  >
                    <p className="text-lg font-medium text-left">
                      Paypal or Credit Card
                    </p>
                    <div className="flex justify-end">
                      <img
                        className="object-contain h-8 ml-3"
                        src="/logo/paypal_with_card.png"
                        alt="Paypal Logo"
                      />
                    </div>
                  </button>
                </div>
              </label>
            </div>

            <Separator />
            {user ? (
              <>
                <div className="px-6 text-xl font-medium text-gray-900">
                  Account Information
                </div>
                <div className="px-6 py-4 text-gray-900">
                  You have been signed in with account{" "}
                  <span className="font-semibold">{user.email}</span>
                </div>
              </>
            ) : (
              <>
                <div className="px-6 py-4 text-xl font-medium text-gray-900">
                  Fill Out Your Information
                </div>
                <div className="px-6 pb-6">
                  <FormInput
                    label="Full Name"
                    type="text"
                    placeholder="Fill with your full name"
                    name="fullname"
                    error={errors.fullname}
                    ref={register({ required: true })}
                  />
                  <FormInput
                    label="Phone Number"
                    type="text"
                    placeholder="Fill with your phone number including country code"
                    name="phone"
                    error={errors.phone}
                    ref={register({ required: true })}
                  />
                  <FormInput
                    label="Email Address"
                    placeholder="Fill with your email address"
                    type="email"
                    name="email"
                    error={errors.email}
                    ref={register({ required: true })}
                  />
                  <FormInput
                    label="Password"
                    placeholder="Fill with secure password"
                    name="password"
                    type="password"
                    error={errors.password}
                    ref={register({ required: true })}
                  />
                </div>
              </>
            )}
          </CardBase>
        </div>
        <div className="col-span-1 lg:col-span-3">
          <div className="sticky" style={{ top: "8px" }}>
            <CardBase className="flex flex-col pb-4">
              <div className="px-6 py-4 text-xl font-medium text-gray-900">
                Purchase Summary
              </div>
              <Separator />
              <div className="grid gap-4 px-6 grid-col-1">
                <div className="flex items-center justify-between col-auto">
                  <span className="flex flex-col">
                    <span className="text-gray-700">Price</span>
                    <span className="pr-4 text-xs font-medium leading-loose text-gray-800">
                      &middot; WhatsApp CS ({capitalize(activePlan.name)}
                      {activePlan.id !== plans[0].id &&
                        ` - ${
                          activePlan.pricing.find((p) => p.id === pricingID)
                            ?.duration
                        } Bulan`}
                      )
                    </span>
                  </span>
                  <span
                    className="flex-shrink-0 font-medium text-gray-900 whitespace-no-wrap"
                    aria-label="Product Price"
                  >
                    Rp {formatNumber(totalPrice)}
                  </span>
                </div>
                {/* <div className="flex items-center justify-between col-auto">
                  <span className="text-gray-700">Discount</span>
                  <span
                    className="font-medium text-gray-900 whitespace-no-wrap"
                    aria-label="Discount"
                  >
                    - Rp 0
                  </span>
                </div> */}
              </div>
              <Separator />
              <div className="grid gap-4 px-6 grid-col-1">
                <div className="flex items-center justify-between col-auto">
                  <span className="text-gray-700">Total</span>
                  <span
                    className="font-medium text-gray-900 whitespace-no-wrap"
                    aria-label="Total Price"
                  >
                    Rp {formatNumber(totalPrice)}
                  </span>
                </div>
              </div>
            </CardBase>
            {signupError && (
              <span className="block px-3 py-2 mt-2 text-sm text-red-700 bg-red-200 rounded">
                {signupError.message.replace(/(.*)\:/, "")}
              </span>
            )}
            {buyError && (
              <span className="block px-3 py-2 mt-2 text-sm text-red-700 bg-red-200 rounded">
                {buyError.message.replace(/(.*)\:/, "")}
              </span>
            )}
            {hasPaidMembership ? (
              <div className="col-span-1 px-3 py-2 mt-4 text-sm text-gray-800 bg-yellow-100 border border-yellow-500 rounded md:col-span-3">
                You currently have a paid membership, to change your membership
                please wait for the active period or contact customer service
              </div>
            ) : (
              <div></div>
            )}
            <button
              className={classnames(
                "w-full text-white font-semibold rounded shadow px-4 py-2 mt-4",
                {
                  "bg-blue-600": !isLoading,
                  "bg-gray-600 cursor-not-allowed":
                    isLoading || hasPaidMembership
                }
              )}
              disabled={isLoading || hasPaidMembership}
              type="submit"
            >
              Complete Purchase
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

const plansQuery = gql`
  query plans {
    plans {
      id
      name
      pricing {
        id
        price
        duration
      }
    }
    user {
      id
      fullname
      email
      membership {
        plan {
          id
          name
        }
      }
    }
  }
`;

const SignupPage = () => {
  const { data, loading } = useQuery<
    {
      plans: Plan[];
      user: User;
    },
    {}
  >(plansQuery);

  const { plan } = useParams<{
    plan?: string;
  }>();

  if (loading) return <div>Loading...</div>;
  if (!data) return <div>Error</div>;

  return <SignupForm {...data} plan={plan} />;
};

export default SignupPage;
