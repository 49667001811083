import React from 'react'
import { CardBase } from "../../components/Card";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import Separator from "../../components/Separator";
import { useMeQuery } from "@wa/graphql/generated";

const SuccessPage = () => {
  const { data } = useMeQuery();

  return (
    <Layout active="Dashboard">
      {/* <Head>
        <title>Daftar Invoice</title>
      </Head> */}
      <Header title="Payment" />
      <div className="max-w-xl px-4 py-10 mx-auto">
        {data && (
          <CardBase className="py-4 text-gray-800">
            <div className="flex flex-col justify-between px-6 md:flex-row item md:items-center md:px-10">
              <h1 className="text-xl md:text-2xl">Pembelian Berhasil</h1>
            </div>
            <Separator />
            <div className="px-6 md:px-10">
              <p className="pt-4 text-lg" aria-label="personal greeting">
                Halo,{" "}
                <span className="font-medium text-gray-900">
                  {data?.me?.fullname}
                </span>
              </p>
              <p className="pt-4 text-sm">
                Terimakasih banyak karena sudah meggunakan produk kami, Sekarang
                kamu sudah bisa menggunakan <b>WhatsApp CS</b>!
              </p>
              <p className="pt-4 text-sm">
                Ikuti petunjuk di{" "}
                <a href="/install" className="font-semibold text-blue-600">
                  halaman ini
                </a>{" "}
                untuk bantuan instalasi ekstensi WhatsApp CS
              </p>
              <p className="pt-10 pb-6 text-sm">
                Salam Hangat,
                <br />
                <span className="font-bold">CEO, Dwi Cahyo Nugroho</span>
              </p>
            </div>
          </CardBase>
        )}
      </div>
    </Layout>
  );
};

export default SuccessPage;
