import { useCouponsQuery } from "@wa/graphql/generated";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import AdminLayout from "../../../components/Admin/AdminLayout";
import { format } from "date-fns";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const AdminCouponsPage = () => {
  const { data, loading, refetch } = useCouponsQuery({
    variables: {
      page: 1,
      query: ""
    }
  });

  const [currentPage, setPage] = useState(1);

  const { register, handleSubmit, getValues } = useForm<{
    page: number;
    query: string;
  }>({
    defaultValues: {
      page: currentPage,
      query: ""
    }
  });

  useEffect(() => {
    refetch({
      page: currentPage,
      query: getValues()["query"]
    });
  }, [currentPage]);

  return (
    <AdminLayout activeMenu="Voucher">
      <form
        className="bg-white rounded shadow"
        onSubmit={handleSubmit(({ page, query }) => {
          refetch({
            page: currentPage,
            query: query
          });
        })}
      >
        <div className="flex items-center justify-between px-4">
          <h2 className="py-3 text-xl font-semibold">Coupons List</h2>
          <Link
            to="/admin/voucher/create"
            className="inline-flex items-center px-3 py-2 space-x-2 font-semibold text-blue-600 rounded hover:bg-blue-100"
          >
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
            <span>Create</span>
          </Link>
        </div>

        <div className="px-4 pb-4">
          <input
            type="text"
            className="text-sm form-input"
            placeholder="Search coupon"
            name="query"
            ref={register}
          />
        </div>

        <ul className="divide-y divide-gray-200">
          {loading ? (
            <div className="pb-4 text-center">Loading...</div>
          ) : (
            data?.coupons.length === 0 && (
              <div className="pb-4 text-center">No Vouchers found</div>
            )
          )}
          {data?.coupons?.map((coupon) => (
            <li className="flex items-center px-5 space-x-4" key={coupon.id}>
              <button
                type="button"
                className="flex-shrink-0 p-1"
                onClick={() => {
                  navigator.clipboard.writeText(coupon.code.toUpperCase()).then(
                    function () {
                      /* clipboard successfully set */
                    },
                    function () {
                      /* clipboard write failed */
                    }
                  );
                }}
              >
                <svg
                  className="w-6 h-6 text-gray-800"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                  />
                </svg>
              </button>
              <Link
                className="block w-full py-4"
                to={`/admin/voucher/${coupon.code}`}
              >
                <div className="flex items-end justify-between">
                  <div>
                    <div className="inline-flex pb-1 space-x-2 font-medium">
                      <span className="uppercase">{coupon?.code}</span>
                      {coupon?.redeemedAt !== null && (
                        <span className="px-2 py-1 text-xs text-white bg-yellow-500 rounded-full">
                          Redeemed
                        </span>
                      )}
                    </div>
                    <div className="text-sm text-gray-700 capitalize">
                      {coupon?.pricing?.plan?.name} -{" "}
                      {coupon?.pricing?.duration} bln
                    </div>
                  </div>
                  {coupon?.redeemedAt && (
                    <div className="flex flex-col items-end justify-between">
                      <span className="text-sm">
                        {coupon?.referrer
                          ? `Referrer: ${coupon?.referrer}`
                          : ""}
                      </span>
                      <span className="text-sm">
                        Redeemed
                        {coupon.user ? (
                          <>
                            {" "}
                            by{" "}
                            <span className="font-semibold">
                              {coupon?.user?.email}
                            </span>
                          </>
                        ) : (
                          ""
                        )}{" "}
                        at{" "}
                        {format(
                          new Date(coupon.redeemedAt),
                          "d LLL yyyy HH:mm"
                        )}
                      </span>
                    </div>
                  )}
                </div>
              </Link>
            </li>
          ))}
        </ul>
        <div className="flex items-center justify-between px-6 py-3 border-t">
          <p className="font-medium">Page {currentPage}</p>
          <div className="space-x-4">
            <button
              onClick={() => {
                setPage(currentPage - 1);
              }}
              type="button"
              disabled={currentPage <= 1}
              className={classnames(
                "px-3 py-1 font-semibold border border-gray-300 rounded-md hover:bg-gray-200",
                {
                  "bg-white text-gray-800": currentPage > 1,
                  "bg-gray-100 text-gray-500 cursor-default": currentPage <= 1
                }
              )}
            >
              Previous
            </button>
            <button
              onClick={() => {
                setPage(currentPage + 1);
              }}
              type="button"
              disabled={data?.coupons.length === 0}
              className={classnames(
                "px-3 py-1 font-semibold border border-gray-300 rounded-md hover:bg-gray-200",
                {
                  "bg-white text-gray-800": data?.coupons.length ?? 0 > 0,
                  "bg-gray-100 text-gray-500 cursor-default":
                    data?.coupons.length === 0
                }
              )}
            >
              Next
            </button>
          </div>
        </div>
      </form>
    </AdminLayout>
  );
};

export default AdminCouponsPage;
