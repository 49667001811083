import React from 'react'
import classnames from "classnames";

type SeparatorProps = {
  borderColor?: string;
  padding?: string;
};

const Separator: React.FC<SeparatorProps> = ({
  borderColor = "border-gray-200",
  padding = "py-3"
}) => {
  return (
    <div className={padding}>
      <div className={classnames("border-b", borderColor)}></div>
    </div>
  );
};

export default Separator;
