import { useMeQuery } from "@wa/graphql/generated";
import { formatWithOptions } from "date-fns/fp";
import { id } from "date-fns/locale";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import Card from "../components/Card";
import Header from "../components/Header";
import useUser from "../components/hooks/useUser";
import Layout from "../components/Layout";
import { capitalize } from "../utils/string";

const Index = () => {
  const { data } = useUser();

  return (
    <Layout active="Dashboard">
      {/* <Head>
        <title>Member Dashboard</title>
      </Head> */}
      <Header title="Dashboard" />

      <div className="grid max-w-5xl grid-cols-1 gap-6 px-4 py-4 mx-auto md:grid-cols-2">
        <Card
          title="Account Summary"
          renderfooter={() => (
            <div className="px-4 pb-3">
              <Link
                to="/settings"
                className="text-sm font-semibold text-blue-600 hover:text-blue-400 upp"
              >
                Account Settings
              </Link>
            </div>
          )}
        >
          <div className="px-4 py-1">
            <div className="grid grid-cols-5 gap-3">
              <div className="col-span-2 text-sm text-gray-600">Full Name</div>
              <div className="col-span-3 text-sm font-medium text-gray-800">
                {data?.me?.fullname || <Skeleton />}
              </div>
              <div className="col-span-2 text-sm text-gray-600">
                Email Address
              </div>
              <div className="col-span-3 text-sm font-medium text-gray-800">
                {data?.me?.email || <Skeleton />}
              </div>
              <div className="col-span-2 text-sm text-gray-600">
                Phone Number
              </div>
              <div className="col-span-3 text-sm font-medium text-gray-800">
                {data?.me?.phone || <Skeleton />}
              </div>
            </div>
          </div>
        </Card>

        <Card
          title="Membership Status"
          renderfooter={() => (
            <div className="px-4 pb-3">
              <Link
                to="/membership"
                className="text-sm font-semibold text-blue-600 hover:text-blue-400 upp"
              >
                Membership Settings
              </Link>
            </div>
          )}
        >
          <div className="px-4 py-1">
            <div className="grid grid-cols-5 gap-3">
              <div className="col-span-2 text-sm text-gray-600">Product</div>
              <div className="col-span-3 text-sm font-medium text-gray-800">
                {(data && "WhatsApp CS") || <Skeleton />}
              </div>
              <div className="col-span-2 text-sm text-gray-600">Membership</div>
              <div className="col-span-3 text-sm font-medium text-gray-800">
                {capitalize(data?.me?.membership.plan.name || "") || (
                  <Skeleton />
                )}
              </div>
              <div className="col-span-2 text-sm text-gray-600">Expired At</div>
              <div className="col-span-3 text-sm font-medium text-gray-800">
                {(data && data?.me?.membership.expiredAt
                  ? formatWithOptions(
                      { locale: id },
                      "d MMMM YYY"
                    )(new Date(data?.me?.membership.expiredAt))
                  : "-") || <Skeleton />}
              </div>
            </div>
          </div>
        </Card>
      </div>
    </Layout>
  );
};

export default Index;
