import { useCreateCouponsMutation, usePlansQuery } from "@wa/graphql/generated";
import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import AdminLayout from "../../../components/Admin/AdminLayout";

const AdminCreateCouponsPage = () => {
  const { data, loading, error } = usePlansQuery();
  const [mutate] = useCreateCouponsMutation();

  const { register, handleSubmit } = useForm<{
    pricing: string;
    amount: string;
    referrer: string;
  }>({
    defaultValues: {
      pricing: "",
      amount: "1",
      referrer: ""
    }
  });

  return (
    <AdminLayout activeMenu="Voucher">
      <div className="bg-white rounded shadow">
        <form
          onSubmit={handleSubmit(async (values) => {
            console.log({ values });
            await mutate({
              variables: {
                ...values,
                amount: parseInt(values.amount)
              }
            });

            window.location.href = "/admin/voucher";
          })}
        >
          <div className="bg-white rounded shadow">
            <div className="flex items-center justify-between px-4">
              <h2 className="py-3 text-xl font-semibold">Create Coupons</h2>
            </div>

            <div className="px-4 pb-4 space-y-4">
              <div>
                <div className="text-sm text-gray-700">Plan</div>
                <select name="pricing" className="form-select" ref={register}>
                  {data?.plans
                    ?.filter((p) => p.name !== "free")
                    ?.map((plan) => (
                      <Fragment key={plan.id}>
                        {plan.pricing.map((pricing) => (
                          <option
                            value={pricing.id}
                            className="capitalize"
                            key={pricing.id}
                          >
                            {plan.name} - {pricing.duration} bulan
                          </option>
                        ))}
                      </Fragment>
                    ))}
                </select>
              </div>
              <div>
                <div className="text-sm text-gray-700">Coupons Amount</div>
                <input
                  type="number"
                  name="amount"
                  autoComplete="off"
                  className="text-sm form-input"
                  min={1}
                  ref={register({
                    required: true
                  })}
                />
              </div>
              <div>
                <div className="text-sm text-gray-700">Referrer (Optional)</div>
                <input
                  type="text"
                  name="referrer"
                  className="text-sm form-input"
                  ref={register}
                />
              </div>
              <button
                type="submit"
                className="px-3 py-2 text-sm font-medium leading-5 text-white bg-blue-600 rounded shadow"
              >
                Create
              </button>
              {error && (
                <span className="block px-3 py-2 mx-5 mb-3 text-sm text-red-700 bg-red-200 rounded">
                  {error.message}
                </span>
              )}
            </div>
          </div>
        </form>
      </div>
    </AdminLayout>
  );
};

export default AdminCreateCouponsPage;
