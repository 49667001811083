import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AuthCheckPayload = {
  __typename?: 'AuthCheckPayload';
  for?: Maybe<Scalars['ID']>;
  loggedOut?: Maybe<Scalars['Boolean']>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type Coupon = {
  __typename?: 'Coupon';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  expiredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  pricing?: Maybe<PlanPrice>;
  redeemedAt?: Maybe<Scalars['DateTime']>;
  referrer?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
};


export type Invoice = {
  __typename?: 'Invoice';
  createdAt: Scalars['DateTime'];
  duration: Scalars['Int'];
  expiredAt: Scalars['DateTime'];
  finalAmount: Scalars['Int'];
  id: Scalars['String'];
  lastChecked?: Maybe<Scalars['DateTime']>;
  method?: Maybe<PaymentMethod>;
  originalAmount: Scalars['Int'];
  plan: Plan;
  status: InvoiceStatus;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export enum InvoiceStatus {
  Cancelled = 'CANCELLED',
  Paid = 'PAID',
  Pending = 'PENDING'
}

export type InvoiceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  paypalPaymentID?: Maybe<Scalars['String']>;
};

export type Membership = {
  __typename?: 'Membership';
  expiredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  plan: Plan;
};

export type MessagePayload = {
  __typename?: 'MessagePayload';
  for?: Maybe<Scalars['ID']>;
  recipient?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  buyPlanSignedIn?: Maybe<SignupResultPaypload>;
  cekongkir?: Maybe<Array<Maybe<OngkirCourier>>>;
  cekresi?: Maybe<ResiPayload>;
  changePassword?: Maybe<Scalars['Boolean']>;
  checkEmail?: Maybe<Scalars['Boolean']>;
  checkInvoice?: Maybe<Scalars['Boolean']>;
  createCoupons: Array<Maybe<Coupon>>;
  createNote?: Maybe<Note>;
  createTemplate?: Maybe<Template>;
  deleteNote?: Maybe<Note>;
  deleteTemplate?: Maybe<Template>;
  deleteUser: Scalars['Boolean'];
  login?: Maybe<AuthPayload>;
  logout?: Maybe<Scalars['Boolean']>;
  redeemCoupon: Scalars['Boolean'];
  requestResetPassword?: Maybe<Scalars['Boolean']>;
  resetPassword?: Maybe<Scalars['Boolean']>;
  signupWithPlan?: Maybe<SignupResultPaypload>;
  updateMembership: Plan;
  updateNote?: Maybe<Note>;
  updateTemplate?: Maybe<Template>;
  updateUserMembership: User;
};


export type MutationBuyPlanSignedInArgs = {
  couponID?: Maybe<Scalars['ID']>;
  method: PaymentMethod;
  pricingID: Scalars['ID'];
};


export type MutationCekongkirArgs = {
  cityFrom: Scalars['Int'];
  cityTo: Scalars['Int'];
  weight: Scalars['Int'];
};


export type MutationCekresiArgs = {
  courier: Scalars['String'];
  resi: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationCheckEmailArgs = {
  email: Scalars['String'];
};


export type MutationCheckInvoiceArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCouponsArgs = {
  amount: Scalars['Int'];
  pricing: Scalars['String'];
  referrer?: Maybe<Scalars['String']>;
};


export type MutationCreateNoteArgs = {
  content: Scalars['String'];
  linkedId: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};


export type MutationCreateTemplateArgs = {
  text: Scalars['String'];
  title: Scalars['String'];
};


export type MutationDeleteNoteArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['ID'];
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  extension?: Maybe<Scalars['Boolean']>;
  password: Scalars['String'];
};


export type MutationRedeemCouponArgs = {
  code: Scalars['String'];
};


export type MutationRequestResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  key: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationSignupWithPlanArgs = {
  couponID?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  fullname: Scalars['String'];
  method: PaymentMethod;
  password: Scalars['String'];
  phone: Scalars['String'];
  pricingID: Scalars['ID'];
};


export type MutationUpdateMembershipArgs = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  pricing: Array<PricingInput>;
};


export type MutationUpdateNoteArgs = {
  content: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};


export type MutationUpdateTemplateArgs = {
  id: Scalars['ID'];
  text: Scalars['String'];
  title: Scalars['String'];
};


export type MutationUpdateUserMembershipArgs = {
  duration: Scalars['Int'];
  planId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type Note = {
  __typename?: 'Note';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  linkedId: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type OngkirCity = {
  __typename?: 'OngkirCity';
  city_id?: Maybe<Scalars['String']>;
  city_name?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  province_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type OngkirCost = {
  __typename?: 'OngkirCost';
  etd?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type OngkirCourier = {
  __typename?: 'OngkirCourier';
  code?: Maybe<Scalars['String']>;
  costs?: Maybe<Array<Maybe<OngkirService>>>;
  name?: Maybe<Scalars['String']>;
};

export type OngkirService = {
  __typename?: 'OngkirService';
  cost?: Maybe<Array<Maybe<OngkirCost>>>;
  description?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
};

export enum PaymentMethod {
  BankTransfer = 'BANK_TRANSFER',
  Paypal = 'PAYPAL'
}

export type Plan = {
  __typename?: 'Plan';
  description: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  pricing: Array<PlanPrice>;
};


export type PlanPricingArgs = {
  after?: Maybe<PlanPriceWhereUniqueInput>;
  before?: Maybe<PlanPriceWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PlanPrice = {
  __typename?: 'PlanPrice';
  duration: Scalars['Int'];
  id: Scalars['String'];
  plan: Plan;
  price: Scalars['Int'];
};

export type PlanPriceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PricingInput = {
  duration: Scalars['Int'];
  id?: Maybe<Scalars['ID']>;
  price: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  cities?: Maybe<Array<Maybe<OngkirCity>>>;
  coupon: Coupon;
  coupons: Array<Coupon>;
  invoice: Invoice;
  invoices?: Maybe<Array<Invoice>>;
  me?: Maybe<User>;
  member?: Maybe<User>;
  members?: Maybe<Array<User>>;
  notes?: Maybe<Array<Maybe<Note>>>;
  plans?: Maybe<Array<Plan>>;
  templates?: Maybe<Array<Maybe<Template>>>;
  user?: Maybe<User>;
};


export type QueryCouponArgs = {
  code: Scalars['String'];
};


export type QueryCouponsArgs = {
  page?: Scalars['Int'];
  query?: Maybe<Scalars['String']>;
};


export type QueryInvoiceArgs = {
  id: Scalars['ID'];
};


export type QueryMemberArgs = {
  id: Scalars['ID'];
};


export type QueryMembersArgs = {
  query?: Scalars['String'];
};


export type QueryNotesArgs = {
  linkedId: Scalars['String'];
};

export type RegisterPayload = {
  __typename?: 'RegisterPayload';
  email?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ResiManifest = {
  __typename?: 'ResiManifest';
  city_name?: Maybe<Scalars['String']>;
  manifest_code?: Maybe<Scalars['String']>;
  manifest_date?: Maybe<Scalars['String']>;
  manifest_description?: Maybe<Scalars['String']>;
  manifest_time?: Maybe<Scalars['String']>;
};

export type ResiPayload = {
  __typename?: 'ResiPayload';
  delivered?: Maybe<Scalars['Boolean']>;
  manifest?: Maybe<Array<Maybe<ResiManifest>>>;
  summary?: Maybe<ResiSummary>;
};

export type ResiSummary = {
  __typename?: 'ResiSummary';
  courier_code?: Maybe<Scalars['String']>;
  courier_name?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  origin?: Maybe<Scalars['String']>;
  receiver_name?: Maybe<Scalars['String']>;
  service_code?: Maybe<Scalars['String']>;
  shipper_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  waybill_date?: Maybe<Scalars['String']>;
  waybill_number?: Maybe<Scalars['String']>;
};

export type SignupResultPaypload = {
  __typename?: 'SignupResultPaypload';
  redirectURL?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  authCheck?: Maybe<AuthCheckPayload>;
  messageSent?: Maybe<MessagePayload>;
};

export type Template = {
  __typename?: 'Template';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  text: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  apiKey: Scalars['String'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  fullname: Scalars['String'];
  id: Scalars['String'];
  invoices: Array<Invoice>;
  membership: Membership;
  phone: Scalars['String'];
};


export type UserInvoicesArgs = {
  after?: Maybe<InvoiceWhereUniqueInput>;
  before?: Maybe<InvoiceWhereUniqueInput>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BuyPlanSignedInMutationVariables = Exact<{
  couponID?: Maybe<Scalars['ID']>;
  method: PaymentMethod;
  pricingID: Scalars['ID'];
}>;


export type BuyPlanSignedInMutation = (
  { __typename?: 'Mutation' }
  & { buyPlanSignedIn?: Maybe<(
    { __typename?: 'SignupResultPaypload' }
    & Pick<SignupResultPaypload, 'redirectURL'>
  )> }
);

export type CekongkirMutationVariables = Exact<{
  cityFrom: Scalars['Int'];
  cityTo: Scalars['Int'];
  weight: Scalars['Int'];
}>;


export type CekongkirMutation = (
  { __typename?: 'Mutation' }
  & { cekongkir?: Maybe<Array<Maybe<(
    { __typename?: 'OngkirCourier' }
    & Pick<OngkirCourier, 'code' | 'name'>
    & { costs?: Maybe<Array<Maybe<(
      { __typename?: 'OngkirService' }
      & Pick<OngkirService, 'service' | 'description'>
      & { cost?: Maybe<Array<Maybe<(
        { __typename?: 'OngkirCost' }
        & Pick<OngkirCost, 'value' | 'etd' | 'note'>
      )>>> }
    )>>> }
  )>>> }
);

export type CekresiMutationVariables = Exact<{
  resi: Scalars['String'];
  courier: Scalars['String'];
}>;


export type CekresiMutation = (
  { __typename?: 'Mutation' }
  & { cekresi?: Maybe<(
    { __typename?: 'ResiPayload' }
    & Pick<ResiPayload, 'delivered'>
    & { summary?: Maybe<(
      { __typename?: 'ResiSummary' }
      & Pick<ResiSummary, 'waybill_number' | 'status' | 'shipper_name' | 'receiver_name' | 'service_code' | 'waybill_date'>
    )>, manifest?: Maybe<Array<Maybe<(
      { __typename?: 'ResiManifest' }
      & Pick<ResiManifest, 'manifest_description' | 'manifest_date' | 'manifest_time' | 'city_name'>
    )>>> }
  )> }
);

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type CheckEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type CheckEmailMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'checkEmail'>
);

export type CheckInvoiceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CheckInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'checkInvoice'>
);

export type CreateCouponsMutationVariables = Exact<{
  pricing: Scalars['String'];
  amount: Scalars['Int'];
  referrer?: Maybe<Scalars['String']>;
}>;


export type CreateCouponsMutation = (
  { __typename?: 'Mutation' }
  & { createCoupons: Array<Maybe<(
    { __typename?: 'Coupon' }
    & Pick<Coupon, 'id' | 'code' | 'referrer' | 'createdAt' | 'redeemedAt'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'fullname'>
    )>, pricing?: Maybe<(
      { __typename?: 'PlanPrice' }
      & Pick<PlanPrice, 'id' | 'duration'>
      & { plan: (
        { __typename?: 'Plan' }
        & Pick<Plan, 'id' | 'name'>
      ) }
    )> }
  )>> }
);

export type CreateNoteMutationVariables = Exact<{
  title: Scalars['String'];
  content: Scalars['String'];
  linkedId: Scalars['String'];
}>;


export type CreateNoteMutation = (
  { __typename?: 'Mutation' }
  & { createNote?: Maybe<(
    { __typename?: 'Note' }
    & Pick<Note, 'id'>
  )> }
);

export type CreateTemplateMutationVariables = Exact<{
  title: Scalars['String'];
  text: Scalars['String'];
}>;


export type CreateTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createTemplate?: Maybe<(
    { __typename?: 'Template' }
    & Pick<Template, 'id'>
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUser'>
);

export type DeleteNoteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteNoteMutation = (
  { __typename?: 'Mutation' }
  & { deleteNote?: Maybe<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'title' | 'content'>
  )> }
);

export type DeleteTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTemplateMutation = (
  { __typename?: 'Mutation' }
  & { deleteTemplate?: Maybe<(
    { __typename?: 'Template' }
    & Pick<Template, 'id' | 'title' | 'text'>
  )> }
);

export type LoginMutationVariables = Exact<{
  extension?: Maybe<Scalars['Boolean']>;
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'token'>
  )> }
);

export type RedeemCouponMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type RedeemCouponMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'redeemCoupon'>
);

export type RequestResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'requestResetPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  key: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type SignupWithPlanMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  fullname: Scalars['String'];
  phone: Scalars['String'];
  couponID?: Maybe<Scalars['ID']>;
  method: PaymentMethod;
  pricingID: Scalars['ID'];
}>;


export type SignupWithPlanMutation = (
  { __typename?: 'Mutation' }
  & { signupWithPlan?: Maybe<(
    { __typename?: 'SignupResultPaypload' }
    & Pick<SignupResultPaypload, 'redirectURL'>
  )> }
);

export type UpdateMembershipMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  pricing: Array<PricingInput> | PricingInput;
  description?: Maybe<Scalars['String']>;
}>;


export type UpdateMembershipMutation = (
  { __typename?: 'Mutation' }
  & { updateMembership: (
    { __typename?: 'Plan' }
    & Pick<Plan, 'id' | 'name' | 'description'>
    & { pricing: Array<(
      { __typename?: 'PlanPrice' }
      & Pick<PlanPrice, 'id' | 'price' | 'duration'>
    )> }
  ) }
);

export type UpdateNoteMutationVariables = Exact<{
  id: Scalars['ID'];
  title: Scalars['String'];
  content: Scalars['String'];
}>;


export type UpdateNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateNote?: Maybe<(
    { __typename?: 'Note' }
    & Pick<Note, 'id'>
  )> }
);

export type UpdateTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
  title: Scalars['String'];
  text: Scalars['String'];
}>;


export type UpdateTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateTemplate?: Maybe<(
    { __typename?: 'Template' }
    & Pick<Template, 'id'>
  )> }
);

export type UpdateUserMembershipMutationVariables = Exact<{
  userId: Scalars['ID'];
  planId: Scalars['ID'];
  duration: Scalars['Int'];
}>;


export type UpdateUserMembershipMutation = (
  { __typename?: 'Mutation' }
  & { updateUserMembership: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type CitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type CitiesQuery = (
  { __typename?: 'Query' }
  & { cities?: Maybe<Array<Maybe<(
    { __typename?: 'OngkirCity' }
    & Pick<OngkirCity, 'city_id' | 'province_id' | 'province' | 'type' | 'city_name' | 'postal_code'>
  )>>> }
);

export type CouponQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type CouponQuery = (
  { __typename?: 'Query' }
  & { coupon: (
    { __typename?: 'Coupon' }
    & Pick<Coupon, 'id' | 'code' | 'referrer' | 'createdAt' | 'redeemedAt'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'fullname'>
    )>, pricing?: Maybe<(
      { __typename?: 'PlanPrice' }
      & Pick<PlanPrice, 'id' | 'duration'>
      & { plan: (
        { __typename?: 'Plan' }
        & Pick<Plan, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type CouponsQueryVariables = Exact<{
  page: Scalars['Int'];
  query?: Maybe<Scalars['String']>;
}>;


export type CouponsQuery = (
  { __typename?: 'Query' }
  & { coupons: Array<(
    { __typename?: 'Coupon' }
    & Pick<Coupon, 'id' | 'code' | 'referrer' | 'createdAt' | 'redeemedAt'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'fullname'>
    )>, pricing?: Maybe<(
      { __typename?: 'PlanPrice' }
      & Pick<PlanPrice, 'id' | 'duration'>
      & { plan: (
        { __typename?: 'Plan' }
        & Pick<Plan, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type InvoiceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type InvoiceQuery = (
  { __typename?: 'Query' }
  & { invoice: (
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'originalAmount' | 'finalAmount' | 'status' | 'method' | 'expiredAt' | 'createdAt' | 'lastChecked'>
    & { plan: (
      { __typename?: 'Plan' }
      & Pick<Plan, 'name'>
    ) }
  ) }
);

export type InvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type InvoicesQuery = (
  { __typename?: 'Query' }
  & { invoices?: Maybe<Array<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'originalAmount' | 'finalAmount' | 'status' | 'method' | 'expiredAt' | 'createdAt' | 'duration'>
    & { plan: (
      { __typename?: 'Plan' }
      & Pick<Plan, 'name'>
    ) }
  )>> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fullname' | 'email' | 'phone' | 'apiKey'>
    & { membership: (
      { __typename?: 'Membership' }
      & Pick<Membership, 'expiredAt'>
      & { plan: (
        { __typename?: 'Plan' }
        & Pick<Plan, 'name'>
      ) }
    ) }
  )> }
);

export type MemberQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MemberQuery = (
  { __typename?: 'Query' }
  & { member?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fullname' | 'email' | 'phone' | 'apiKey' | 'createdAt'>
    & { membership: (
      { __typename?: 'Membership' }
      & Pick<Membership, 'expiredAt'>
      & { plan: (
        { __typename?: 'Plan' }
        & Pick<Plan, 'id' | 'name'>
        & { pricing: Array<(
          { __typename?: 'PlanPrice' }
          & Pick<PlanPrice, 'price' | 'duration'>
        )> }
      ) }
    ), invoices: Array<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'originalAmount' | 'finalAmount' | 'status' | 'method' | 'expiredAt' | 'createdAt' | 'lastChecked'>
      & { plan: (
        { __typename?: 'Plan' }
        & Pick<Plan, 'name'>
      ) }
    )> }
  )>, plans?: Maybe<Array<(
    { __typename?: 'Plan' }
    & Pick<Plan, 'id' | 'name' | 'description'>
    & { pricing: Array<(
      { __typename?: 'PlanPrice' }
      & Pick<PlanPrice, 'price' | 'duration'>
    )> }
  )>> }
);

export type MembersQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
}>;


export type MembersQuery = (
  { __typename?: 'Query' }
  & { members?: Maybe<Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fullname' | 'email' | 'phone' | 'apiKey' | 'createdAt'>
    & { membership: (
      { __typename?: 'Membership' }
      & Pick<Membership, 'expiredAt'>
      & { plan: (
        { __typename?: 'Plan' }
        & Pick<Plan, 'name'>
      ) }
    ) }
  )>> }
);

export type NotesQueryVariables = Exact<{
  linkedId: Scalars['String'];
}>;


export type NotesQuery = (
  { __typename?: 'Query' }
  & { notes?: Maybe<Array<Maybe<(
    { __typename?: 'Note' }
    & Pick<Note, 'id' | 'title' | 'content'>
  )>>> }
);

export type PlansQueryVariables = Exact<{ [key: string]: never; }>;


export type PlansQuery = (
  { __typename?: 'Query' }
  & { plans?: Maybe<Array<(
    { __typename?: 'Plan' }
    & Pick<Plan, 'id' | 'name' | 'description'>
    & { pricing: Array<(
      { __typename?: 'PlanPrice' }
      & Pick<PlanPrice, 'id' | 'price' | 'duration'>
    )> }
  )>>, me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fullname' | 'email' | 'phone' | 'apiKey'>
    & { membership: (
      { __typename?: 'Membership' }
      & Pick<Membership, 'expiredAt'>
      & { plan: (
        { __typename?: 'Plan' }
        & Pick<Plan, 'name'>
      ) }
    ) }
  )> }
);

export type SimplepPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type SimplepPlansQuery = (
  { __typename?: 'Query' }
  & { plans?: Maybe<Array<(
    { __typename?: 'Plan' }
    & Pick<Plan, 'id' | 'name' | 'description'>
    & { pricing: Array<(
      { __typename?: 'PlanPrice' }
      & Pick<PlanPrice, 'id' | 'price' | 'duration'>
    )> }
  )>> }
);

export type TemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type TemplatesQuery = (
  { __typename?: 'Query' }
  & { templates?: Maybe<Array<Maybe<(
    { __typename?: 'Template' }
    & Pick<Template, 'id' | 'title' | 'text'>
  )>>> }
);

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'fullname' | 'email'>
  )> }
);

export type AuthCheckSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AuthCheckSubscription = (
  { __typename?: 'Subscription' }
  & { authCheck?: Maybe<(
    { __typename?: 'AuthCheckPayload' }
    & Pick<AuthCheckPayload, 'for' | 'loggedOut'>
  )> }
);

export type MessageSentSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type MessageSentSubscription = (
  { __typename?: 'Subscription' }
  & { messageSent?: Maybe<(
    { __typename?: 'MessagePayload' }
    & Pick<MessagePayload, 'for' | 'recipient' | 'text'>
  )> }
);



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AuthCheckPayload: ResolverTypeWrapper<AuthCheckPayload>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  AuthPayload: ResolverTypeWrapper<AuthPayload>;
  String: ResolverTypeWrapper<Scalars['String']>;
  Coupon: ResolverTypeWrapper<Coupon>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  Invoice: ResolverTypeWrapper<Invoice>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  InvoiceStatus: InvoiceStatus;
  InvoiceWhereUniqueInput: InvoiceWhereUniqueInput;
  Membership: ResolverTypeWrapper<Membership>;
  MessagePayload: ResolverTypeWrapper<MessagePayload>;
  Mutation: ResolverTypeWrapper<{}>;
  Note: ResolverTypeWrapper<Note>;
  OngkirCity: ResolverTypeWrapper<OngkirCity>;
  OngkirCost: ResolverTypeWrapper<OngkirCost>;
  OngkirCourier: ResolverTypeWrapper<OngkirCourier>;
  OngkirService: ResolverTypeWrapper<OngkirService>;
  PaymentMethod: PaymentMethod;
  Plan: ResolverTypeWrapper<Plan>;
  PlanPrice: ResolverTypeWrapper<PlanPrice>;
  PlanPriceWhereUniqueInput: PlanPriceWhereUniqueInput;
  PricingInput: PricingInput;
  Query: ResolverTypeWrapper<{}>;
  RegisterPayload: ResolverTypeWrapper<RegisterPayload>;
  ResiManifest: ResolverTypeWrapper<ResiManifest>;
  ResiPayload: ResolverTypeWrapper<ResiPayload>;
  ResiSummary: ResolverTypeWrapper<ResiSummary>;
  SignupResultPaypload: ResolverTypeWrapper<SignupResultPaypload>;
  Subscription: ResolverTypeWrapper<{}>;
  Template: ResolverTypeWrapper<Template>;
  User: ResolverTypeWrapper<User>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AuthCheckPayload: AuthCheckPayload;
  ID: Scalars['ID'];
  Boolean: Scalars['Boolean'];
  AuthPayload: AuthPayload;
  String: Scalars['String'];
  Coupon: Coupon;
  DateTime: Scalars['DateTime'];
  Invoice: Invoice;
  Int: Scalars['Int'];
  InvoiceWhereUniqueInput: InvoiceWhereUniqueInput;
  Membership: Membership;
  MessagePayload: MessagePayload;
  Mutation: {};
  Note: Note;
  OngkirCity: OngkirCity;
  OngkirCost: OngkirCost;
  OngkirCourier: OngkirCourier;
  OngkirService: OngkirService;
  Plan: Plan;
  PlanPrice: PlanPrice;
  PlanPriceWhereUniqueInput: PlanPriceWhereUniqueInput;
  PricingInput: PricingInput;
  Query: {};
  RegisterPayload: RegisterPayload;
  ResiManifest: ResiManifest;
  ResiPayload: ResiPayload;
  ResiSummary: ResiSummary;
  SignupResultPaypload: SignupResultPaypload;
  Subscription: {};
  Template: Template;
  User: User;
};

export type AuthCheckPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['AuthCheckPayload'] = ResolversParentTypes['AuthCheckPayload']> = {
  for?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  loggedOut?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['AuthPayload'] = ResolversParentTypes['AuthPayload']> = {
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CouponResolvers<ContextType = any, ParentType extends ResolversParentTypes['Coupon'] = ResolversParentTypes['Coupon']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  expiredAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pricing?: Resolver<Maybe<ResolversTypes['PlanPrice']>, ParentType, ContextType>;
  redeemedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  referrer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type InvoiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Invoice'] = ResolversParentTypes['Invoice']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  expiredAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  finalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastChecked?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  method?: Resolver<Maybe<ResolversTypes['PaymentMethod']>, ParentType, ContextType>;
  originalAmount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  plan?: Resolver<ResolversTypes['Plan'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['InvoiceStatus'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MembershipResolvers<ContextType = any, ParentType extends ResolversParentTypes['Membership'] = ResolversParentTypes['Membership']> = {
  expiredAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  plan?: Resolver<ResolversTypes['Plan'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessagePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessagePayload'] = ResolversParentTypes['MessagePayload']> = {
  for?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  recipient?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  text?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  buyPlanSignedIn?: Resolver<Maybe<ResolversTypes['SignupResultPaypload']>, ParentType, ContextType, RequireFields<MutationBuyPlanSignedInArgs, 'method' | 'pricingID'>>;
  cekongkir?: Resolver<Maybe<Array<Maybe<ResolversTypes['OngkirCourier']>>>, ParentType, ContextType, RequireFields<MutationCekongkirArgs, 'cityFrom' | 'cityTo' | 'weight'>>;
  cekresi?: Resolver<Maybe<ResolversTypes['ResiPayload']>, ParentType, ContextType, RequireFields<MutationCekresiArgs, 'courier' | 'resi'>>;
  changePassword?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationChangePasswordArgs, 'newPassword' | 'oldPassword'>>;
  checkEmail?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCheckEmailArgs, 'email'>>;
  checkInvoice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationCheckInvoiceArgs, 'id'>>;
  createCoupons?: Resolver<Array<Maybe<ResolversTypes['Coupon']>>, ParentType, ContextType, RequireFields<MutationCreateCouponsArgs, 'amount' | 'pricing'>>;
  createNote?: Resolver<Maybe<ResolversTypes['Note']>, ParentType, ContextType, RequireFields<MutationCreateNoteArgs, 'content' | 'linkedId'>>;
  createTemplate?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType, RequireFields<MutationCreateTemplateArgs, 'text' | 'title'>>;
  deleteNote?: Resolver<Maybe<ResolversTypes['Note']>, ParentType, ContextType, RequireFields<MutationDeleteNoteArgs, 'id'>>;
  deleteTemplate?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType, RequireFields<MutationDeleteTemplateArgs, 'id'>>;
  deleteUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteUserArgs, 'userId'>>;
  login?: Resolver<Maybe<ResolversTypes['AuthPayload']>, ParentType, ContextType, RequireFields<MutationLoginArgs, 'email' | 'extension' | 'password'>>;
  logout?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  redeemCoupon?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRedeemCouponArgs, 'code'>>;
  requestResetPassword?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationRequestResetPasswordArgs, 'email'>>;
  resetPassword?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationResetPasswordArgs, 'key' | 'newPassword'>>;
  signupWithPlan?: Resolver<Maybe<ResolversTypes['SignupResultPaypload']>, ParentType, ContextType, RequireFields<MutationSignupWithPlanArgs, 'email' | 'fullname' | 'method' | 'password' | 'phone' | 'pricingID'>>;
  updateMembership?: Resolver<ResolversTypes['Plan'], ParentType, ContextType, RequireFields<MutationUpdateMembershipArgs, 'id' | 'name' | 'pricing'>>;
  updateNote?: Resolver<Maybe<ResolversTypes['Note']>, ParentType, ContextType, RequireFields<MutationUpdateNoteArgs, 'content' | 'id' | 'title'>>;
  updateTemplate?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType, RequireFields<MutationUpdateTemplateArgs, 'id' | 'text' | 'title'>>;
  updateUserMembership?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateUserMembershipArgs, 'duration' | 'planId' | 'userId'>>;
};

export type NoteResolvers<ContextType = any, ParentType extends ResolversParentTypes['Note'] = ResolversParentTypes['Note']> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  linkedId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OngkirCityResolvers<ContextType = any, ParentType extends ResolversParentTypes['OngkirCity'] = ResolversParentTypes['OngkirCity']> = {
  city_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postal_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  province?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  province_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OngkirCostResolvers<ContextType = any, ParentType extends ResolversParentTypes['OngkirCost'] = ResolversParentTypes['OngkirCost']> = {
  etd?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OngkirCourierResolvers<ContextType = any, ParentType extends ResolversParentTypes['OngkirCourier'] = ResolversParentTypes['OngkirCourier']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  costs?: Resolver<Maybe<Array<Maybe<ResolversTypes['OngkirService']>>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OngkirServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['OngkirService'] = ResolversParentTypes['OngkirService']> = {
  cost?: Resolver<Maybe<Array<Maybe<ResolversTypes['OngkirCost']>>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  service?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['Plan'] = ResolversParentTypes['Plan']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pricing?: Resolver<Array<ResolversTypes['PlanPrice']>, ParentType, ContextType, RequireFields<PlanPricingArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlanPriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlanPrice'] = ResolversParentTypes['PlanPrice']> = {
  duration?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  plan?: Resolver<ResolversTypes['Plan'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  cities?: Resolver<Maybe<Array<Maybe<ResolversTypes['OngkirCity']>>>, ParentType, ContextType>;
  coupon?: Resolver<ResolversTypes['Coupon'], ParentType, ContextType, RequireFields<QueryCouponArgs, 'code'>>;
  coupons?: Resolver<Array<ResolversTypes['Coupon']>, ParentType, ContextType, RequireFields<QueryCouponsArgs, 'page'>>;
  invoice?: Resolver<ResolversTypes['Invoice'], ParentType, ContextType, RequireFields<QueryInvoiceArgs, 'id'>>;
  invoices?: Resolver<Maybe<Array<ResolversTypes['Invoice']>>, ParentType, ContextType>;
  me?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  member?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryMemberArgs, 'id'>>;
  members?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType, RequireFields<QueryMembersArgs, 'query'>>;
  notes?: Resolver<Maybe<Array<Maybe<ResolversTypes['Note']>>>, ParentType, ContextType, RequireFields<QueryNotesArgs, 'linkedId'>>;
  plans?: Resolver<Maybe<Array<ResolversTypes['Plan']>>, ParentType, ContextType>;
  templates?: Resolver<Maybe<Array<Maybe<ResolversTypes['Template']>>>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
};

export type RegisterPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['RegisterPayload'] = ResolversParentTypes['RegisterPayload']> = {
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResiManifestResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResiManifest'] = ResolversParentTypes['ResiManifest']> = {
  city_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manifest_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manifest_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manifest_description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manifest_time?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResiPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResiPayload'] = ResolversParentTypes['ResiPayload']> = {
  delivered?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  manifest?: Resolver<Maybe<Array<Maybe<ResolversTypes['ResiManifest']>>>, ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['ResiSummary']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResiSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResiSummary'] = ResolversParentTypes['ResiSummary']> = {
  courier_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courier_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  destination?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  origin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receiver_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  service_code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipper_name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  waybill_date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  waybill_number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignupResultPayploadResolvers<ContextType = any, ParentType extends ResolversParentTypes['SignupResultPaypload'] = ResolversParentTypes['SignupResultPaypload']> = {
  redirectURL?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = {
  authCheck?: SubscriptionResolver<Maybe<ResolversTypes['AuthCheckPayload']>, "authCheck", ParentType, ContextType>;
  messageSent?: SubscriptionResolver<Maybe<ResolversTypes['MessagePayload']>, "messageSent", ParentType, ContextType>;
};

export type TemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Template'] = ResolversParentTypes['Template']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  apiKey?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fullname?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  invoices?: Resolver<Array<ResolversTypes['Invoice']>, ParentType, ContextType, RequireFields<UserInvoicesArgs, never>>;
  membership?: Resolver<ResolversTypes['Membership'], ParentType, ContextType>;
  phone?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AuthCheckPayload?: AuthCheckPayloadResolvers<ContextType>;
  AuthPayload?: AuthPayloadResolvers<ContextType>;
  Coupon?: CouponResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  Invoice?: InvoiceResolvers<ContextType>;
  Membership?: MembershipResolvers<ContextType>;
  MessagePayload?: MessagePayloadResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Note?: NoteResolvers<ContextType>;
  OngkirCity?: OngkirCityResolvers<ContextType>;
  OngkirCost?: OngkirCostResolvers<ContextType>;
  OngkirCourier?: OngkirCourierResolvers<ContextType>;
  OngkirService?: OngkirServiceResolvers<ContextType>;
  Plan?: PlanResolvers<ContextType>;
  PlanPrice?: PlanPriceResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RegisterPayload?: RegisterPayloadResolvers<ContextType>;
  ResiManifest?: ResiManifestResolvers<ContextType>;
  ResiPayload?: ResiPayloadResolvers<ContextType>;
  ResiSummary?: ResiSummaryResolvers<ContextType>;
  SignupResultPaypload?: SignupResultPayploadResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  Template?: TemplateResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
};


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;


export const BuyPlanSignedInDocument = gql`
    mutation buyPlanSignedIn($couponID: ID, $method: PaymentMethod!, $pricingID: ID!) {
  buyPlanSignedIn(couponID: $couponID, method: $method, pricingID: $pricingID) {
    redirectURL
  }
}
    `;
export type BuyPlanSignedInMutationFn = Apollo.MutationFunction<BuyPlanSignedInMutation, BuyPlanSignedInMutationVariables>;

/**
 * __useBuyPlanSignedInMutation__
 *
 * To run a mutation, you first call `useBuyPlanSignedInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuyPlanSignedInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buyPlanSignedInMutation, { data, loading, error }] = useBuyPlanSignedInMutation({
 *   variables: {
 *      couponID: // value for 'couponID'
 *      method: // value for 'method'
 *      pricingID: // value for 'pricingID'
 *   },
 * });
 */
export function useBuyPlanSignedInMutation(baseOptions?: Apollo.MutationHookOptions<BuyPlanSignedInMutation, BuyPlanSignedInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BuyPlanSignedInMutation, BuyPlanSignedInMutationVariables>(BuyPlanSignedInDocument, options);
      }
export type BuyPlanSignedInMutationHookResult = ReturnType<typeof useBuyPlanSignedInMutation>;
export type BuyPlanSignedInMutationResult = Apollo.MutationResult<BuyPlanSignedInMutation>;
export type BuyPlanSignedInMutationOptions = Apollo.BaseMutationOptions<BuyPlanSignedInMutation, BuyPlanSignedInMutationVariables>;
export const CekongkirDocument = gql`
    mutation cekongkir($cityFrom: Int!, $cityTo: Int!, $weight: Int!) {
  cekongkir(cityFrom: $cityFrom, cityTo: $cityTo, weight: $weight) {
    code
    name
    costs {
      service
      description
      cost {
        value
        etd
        note
      }
    }
  }
}
    `;
export type CekongkirMutationFn = Apollo.MutationFunction<CekongkirMutation, CekongkirMutationVariables>;

/**
 * __useCekongkirMutation__
 *
 * To run a mutation, you first call `useCekongkirMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCekongkirMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cekongkirMutation, { data, loading, error }] = useCekongkirMutation({
 *   variables: {
 *      cityFrom: // value for 'cityFrom'
 *      cityTo: // value for 'cityTo'
 *      weight: // value for 'weight'
 *   },
 * });
 */
export function useCekongkirMutation(baseOptions?: Apollo.MutationHookOptions<CekongkirMutation, CekongkirMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CekongkirMutation, CekongkirMutationVariables>(CekongkirDocument, options);
      }
export type CekongkirMutationHookResult = ReturnType<typeof useCekongkirMutation>;
export type CekongkirMutationResult = Apollo.MutationResult<CekongkirMutation>;
export type CekongkirMutationOptions = Apollo.BaseMutationOptions<CekongkirMutation, CekongkirMutationVariables>;
export const CekresiDocument = gql`
    mutation cekresi($resi: String!, $courier: String!) {
  cekresi(resi: $resi, courier: $courier) {
    delivered
    summary {
      waybill_number
      status
      shipper_name
      receiver_name
      service_code
      waybill_date
    }
    manifest {
      manifest_description
      manifest_date
      manifest_time
      city_name
    }
  }
}
    `;
export type CekresiMutationFn = Apollo.MutationFunction<CekresiMutation, CekresiMutationVariables>;

/**
 * __useCekresiMutation__
 *
 * To run a mutation, you first call `useCekresiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCekresiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cekresiMutation, { data, loading, error }] = useCekresiMutation({
 *   variables: {
 *      resi: // value for 'resi'
 *      courier: // value for 'courier'
 *   },
 * });
 */
export function useCekresiMutation(baseOptions?: Apollo.MutationHookOptions<CekresiMutation, CekresiMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CekresiMutation, CekresiMutationVariables>(CekresiDocument, options);
      }
export type CekresiMutationHookResult = ReturnType<typeof useCekresiMutation>;
export type CekresiMutationResult = Apollo.MutationResult<CekresiMutation>;
export type CekresiMutationOptions = Apollo.BaseMutationOptions<CekresiMutation, CekresiMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($oldPassword: String!, $newPassword: String!) {
  changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CheckEmailDocument = gql`
    mutation checkEmail($email: String!) {
  checkEmail(email: $email)
}
    `;
export type CheckEmailMutationFn = Apollo.MutationFunction<CheckEmailMutation, CheckEmailMutationVariables>;

/**
 * __useCheckEmailMutation__
 *
 * To run a mutation, you first call `useCheckEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkEmailMutation, { data, loading, error }] = useCheckEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckEmailMutation(baseOptions?: Apollo.MutationHookOptions<CheckEmailMutation, CheckEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckEmailMutation, CheckEmailMutationVariables>(CheckEmailDocument, options);
      }
export type CheckEmailMutationHookResult = ReturnType<typeof useCheckEmailMutation>;
export type CheckEmailMutationResult = Apollo.MutationResult<CheckEmailMutation>;
export type CheckEmailMutationOptions = Apollo.BaseMutationOptions<CheckEmailMutation, CheckEmailMutationVariables>;
export const CheckInvoiceDocument = gql`
    mutation checkInvoice($id: ID!) {
  checkInvoice(id: $id)
}
    `;
export type CheckInvoiceMutationFn = Apollo.MutationFunction<CheckInvoiceMutation, CheckInvoiceMutationVariables>;

/**
 * __useCheckInvoiceMutation__
 *
 * To run a mutation, you first call `useCheckInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkInvoiceMutation, { data, loading, error }] = useCheckInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCheckInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CheckInvoiceMutation, CheckInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckInvoiceMutation, CheckInvoiceMutationVariables>(CheckInvoiceDocument, options);
      }
export type CheckInvoiceMutationHookResult = ReturnType<typeof useCheckInvoiceMutation>;
export type CheckInvoiceMutationResult = Apollo.MutationResult<CheckInvoiceMutation>;
export type CheckInvoiceMutationOptions = Apollo.BaseMutationOptions<CheckInvoiceMutation, CheckInvoiceMutationVariables>;
export const CreateCouponsDocument = gql`
    mutation createCoupons($pricing: String!, $amount: Int!, $referrer: String) {
  createCoupons(pricing: $pricing, amount: $amount, referrer: $referrer) {
    id
    code
    referrer
    createdAt
    redeemedAt
    user {
      id
      email
      fullname
    }
    pricing {
      id
      duration
      plan {
        id
        name
      }
    }
  }
}
    `;
export type CreateCouponsMutationFn = Apollo.MutationFunction<CreateCouponsMutation, CreateCouponsMutationVariables>;

/**
 * __useCreateCouponsMutation__
 *
 * To run a mutation, you first call `useCreateCouponsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCouponsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCouponsMutation, { data, loading, error }] = useCreateCouponsMutation({
 *   variables: {
 *      pricing: // value for 'pricing'
 *      amount: // value for 'amount'
 *      referrer: // value for 'referrer'
 *   },
 * });
 */
export function useCreateCouponsMutation(baseOptions?: Apollo.MutationHookOptions<CreateCouponsMutation, CreateCouponsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCouponsMutation, CreateCouponsMutationVariables>(CreateCouponsDocument, options);
      }
export type CreateCouponsMutationHookResult = ReturnType<typeof useCreateCouponsMutation>;
export type CreateCouponsMutationResult = Apollo.MutationResult<CreateCouponsMutation>;
export type CreateCouponsMutationOptions = Apollo.BaseMutationOptions<CreateCouponsMutation, CreateCouponsMutationVariables>;
export const CreateNoteDocument = gql`
    mutation createNote($title: String!, $content: String!, $linkedId: String!) {
  createNote(title: $title, content: $content, linkedId: $linkedId) {
    id
  }
}
    `;
export type CreateNoteMutationFn = Apollo.MutationFunction<CreateNoteMutation, CreateNoteMutationVariables>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      title: // value for 'title'
 *      content: // value for 'content'
 *      linkedId: // value for 'linkedId'
 *   },
 * });
 */
export function useCreateNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(CreateNoteDocument, options);
      }
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = Apollo.BaseMutationOptions<CreateNoteMutation, CreateNoteMutationVariables>;
export const CreateTemplateDocument = gql`
    mutation createTemplate($title: String!, $text: String!) {
  createTemplate(title: $title, text: $text) {
    id
  }
}
    `;
export type CreateTemplateMutationFn = Apollo.MutationFunction<CreateTemplateMutation, CreateTemplateMutationVariables>;

/**
 * __useCreateTemplateMutation__
 *
 * To run a mutation, you first call `useCreateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateMutation, { data, loading, error }] = useCreateTemplateMutation({
 *   variables: {
 *      title: // value for 'title'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCreateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateTemplateMutation, CreateTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTemplateMutation, CreateTemplateMutationVariables>(CreateTemplateDocument, options);
      }
export type CreateTemplateMutationHookResult = ReturnType<typeof useCreateTemplateMutation>;
export type CreateTemplateMutationResult = Apollo.MutationResult<CreateTemplateMutation>;
export type CreateTemplateMutationOptions = Apollo.BaseMutationOptions<CreateTemplateMutation, CreateTemplateMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($userId: ID!) {
  deleteUser(userId: $userId)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const DeleteNoteDocument = gql`
    mutation deleteNote($id: ID!) {
  deleteNote(id: $id) {
    id
    title
    content
  }
}
    `;
export type DeleteNoteMutationFn = Apollo.MutationFunction<DeleteNoteMutation, DeleteNoteMutationVariables>;

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNoteMutation, DeleteNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(DeleteNoteDocument, options);
      }
export type DeleteNoteMutationHookResult = ReturnType<typeof useDeleteNoteMutation>;
export type DeleteNoteMutationResult = Apollo.MutationResult<DeleteNoteMutation>;
export type DeleteNoteMutationOptions = Apollo.BaseMutationOptions<DeleteNoteMutation, DeleteNoteMutationVariables>;
export const DeleteTemplateDocument = gql`
    mutation deleteTemplate($id: ID!) {
  deleteTemplate(id: $id) {
    id
    title
    text
  }
}
    `;
export type DeleteTemplateMutationFn = Apollo.MutationFunction<DeleteTemplateMutation, DeleteTemplateMutationVariables>;

/**
 * __useDeleteTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateMutation, { data, loading, error }] = useDeleteTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTemplateMutation, DeleteTemplateMutationVariables>(DeleteTemplateDocument, options);
      }
export type DeleteTemplateMutationHookResult = ReturnType<typeof useDeleteTemplateMutation>;
export type DeleteTemplateMutationResult = Apollo.MutationResult<DeleteTemplateMutation>;
export type DeleteTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>;
export const LoginDocument = gql`
    mutation login($extension: Boolean, $email: String!, $password: String!) {
  login(extension: $extension, email: $email, password: $password) {
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      extension: // value for 'extension'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RedeemCouponDocument = gql`
    mutation redeemCoupon($code: String!) {
  redeemCoupon(code: $code)
}
    `;
export type RedeemCouponMutationFn = Apollo.MutationFunction<RedeemCouponMutation, RedeemCouponMutationVariables>;

/**
 * __useRedeemCouponMutation__
 *
 * To run a mutation, you first call `useRedeemCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemCouponMutation, { data, loading, error }] = useRedeemCouponMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useRedeemCouponMutation(baseOptions?: Apollo.MutationHookOptions<RedeemCouponMutation, RedeemCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RedeemCouponMutation, RedeemCouponMutationVariables>(RedeemCouponDocument, options);
      }
export type RedeemCouponMutationHookResult = ReturnType<typeof useRedeemCouponMutation>;
export type RedeemCouponMutationResult = Apollo.MutationResult<RedeemCouponMutation>;
export type RedeemCouponMutationOptions = Apollo.BaseMutationOptions<RedeemCouponMutation, RedeemCouponMutationVariables>;
export const RequestResetPasswordDocument = gql`
    mutation requestResetPassword($email: String!) {
  requestResetPassword(email: $email)
}
    `;
export type RequestResetPasswordMutationFn = Apollo.MutationFunction<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;

/**
 * __useRequestResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetPasswordMutation, { data, loading, error }] = useRequestResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>(RequestResetPasswordDocument, options);
      }
export type RequestResetPasswordMutationHookResult = ReturnType<typeof useRequestResetPasswordMutation>;
export type RequestResetPasswordMutationResult = Apollo.MutationResult<RequestResetPasswordMutation>;
export type RequestResetPasswordMutationOptions = Apollo.BaseMutationOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($key: String!, $newPassword: String!) {
  resetPassword(key: $key, newPassword: $newPassword)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      key: // value for 'key'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SignupWithPlanDocument = gql`
    mutation signupWithPlan($email: String!, $password: String!, $fullname: String!, $phone: String!, $couponID: ID, $method: PaymentMethod!, $pricingID: ID!) {
  signupWithPlan(
    email: $email
    password: $password
    fullname: $fullname
    phone: $phone
    couponID: $couponID
    method: $method
    pricingID: $pricingID
  ) {
    redirectURL
  }
}
    `;
export type SignupWithPlanMutationFn = Apollo.MutationFunction<SignupWithPlanMutation, SignupWithPlanMutationVariables>;

/**
 * __useSignupWithPlanMutation__
 *
 * To run a mutation, you first call `useSignupWithPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupWithPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupWithPlanMutation, { data, loading, error }] = useSignupWithPlanMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      fullname: // value for 'fullname'
 *      phone: // value for 'phone'
 *      couponID: // value for 'couponID'
 *      method: // value for 'method'
 *      pricingID: // value for 'pricingID'
 *   },
 * });
 */
export function useSignupWithPlanMutation(baseOptions?: Apollo.MutationHookOptions<SignupWithPlanMutation, SignupWithPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupWithPlanMutation, SignupWithPlanMutationVariables>(SignupWithPlanDocument, options);
      }
export type SignupWithPlanMutationHookResult = ReturnType<typeof useSignupWithPlanMutation>;
export type SignupWithPlanMutationResult = Apollo.MutationResult<SignupWithPlanMutation>;
export type SignupWithPlanMutationOptions = Apollo.BaseMutationOptions<SignupWithPlanMutation, SignupWithPlanMutationVariables>;
export const UpdateMembershipDocument = gql`
    mutation updateMembership($id: ID!, $name: String!, $pricing: [PricingInput!]!, $description: String) {
  updateMembership(
    id: $id
    name: $name
    pricing: $pricing
    description: $description
  ) {
    id
    name
    pricing {
      id
      price
      duration
    }
    description
  }
}
    `;
export type UpdateMembershipMutationFn = Apollo.MutationFunction<UpdateMembershipMutation, UpdateMembershipMutationVariables>;

/**
 * __useUpdateMembershipMutation__
 *
 * To run a mutation, you first call `useUpdateMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMembershipMutation, { data, loading, error }] = useUpdateMembershipMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      pricing: // value for 'pricing'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateMembershipMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMembershipMutation, UpdateMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMembershipMutation, UpdateMembershipMutationVariables>(UpdateMembershipDocument, options);
      }
export type UpdateMembershipMutationHookResult = ReturnType<typeof useUpdateMembershipMutation>;
export type UpdateMembershipMutationResult = Apollo.MutationResult<UpdateMembershipMutation>;
export type UpdateMembershipMutationOptions = Apollo.BaseMutationOptions<UpdateMembershipMutation, UpdateMembershipMutationVariables>;
export const UpdateNoteDocument = gql`
    mutation updateNote($id: ID!, $title: String!, $content: String!) {
  updateNote(id: $id, title: $title, content: $content) {
    id
  }
}
    `;
export type UpdateNoteMutationFn = Apollo.MutationFunction<UpdateNoteMutation, UpdateNoteMutationVariables>;

/**
 * __useUpdateNoteMutation__
 *
 * To run a mutation, you first call `useUpdateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoteMutation, { data, loading, error }] = useUpdateNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpdateNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNoteMutation, UpdateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNoteMutation, UpdateNoteMutationVariables>(UpdateNoteDocument, options);
      }
export type UpdateNoteMutationHookResult = ReturnType<typeof useUpdateNoteMutation>;
export type UpdateNoteMutationResult = Apollo.MutationResult<UpdateNoteMutation>;
export type UpdateNoteMutationOptions = Apollo.BaseMutationOptions<UpdateNoteMutation, UpdateNoteMutationVariables>;
export const UpdateTemplateDocument = gql`
    mutation updateTemplate($id: ID!, $title: String!, $text: String!) {
  updateTemplate(id: $id, title: $title, text: $text) {
    id
  }
}
    `;
export type UpdateTemplateMutationFn = Apollo.MutationFunction<UpdateTemplateMutation, UpdateTemplateMutationVariables>;

/**
 * __useUpdateTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateMutation, { data, loading, error }] = useUpdateTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useUpdateTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTemplateMutation, UpdateTemplateMutationVariables>(UpdateTemplateDocument, options);
      }
export type UpdateTemplateMutationHookResult = ReturnType<typeof useUpdateTemplateMutation>;
export type UpdateTemplateMutationResult = Apollo.MutationResult<UpdateTemplateMutation>;
export type UpdateTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateTemplateMutation, UpdateTemplateMutationVariables>;
export const UpdateUserMembershipDocument = gql`
    mutation updateUserMembership($userId: ID!, $planId: ID!, $duration: Int!) {
  updateUserMembership(userId: $userId, planId: $planId, duration: $duration) {
    id
  }
}
    `;
export type UpdateUserMembershipMutationFn = Apollo.MutationFunction<UpdateUserMembershipMutation, UpdateUserMembershipMutationVariables>;

/**
 * __useUpdateUserMembershipMutation__
 *
 * To run a mutation, you first call `useUpdateUserMembershipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMembershipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMembershipMutation, { data, loading, error }] = useUpdateUserMembershipMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      planId: // value for 'planId'
 *      duration: // value for 'duration'
 *   },
 * });
 */
export function useUpdateUserMembershipMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMembershipMutation, UpdateUserMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMembershipMutation, UpdateUserMembershipMutationVariables>(UpdateUserMembershipDocument, options);
      }
export type UpdateUserMembershipMutationHookResult = ReturnType<typeof useUpdateUserMembershipMutation>;
export type UpdateUserMembershipMutationResult = Apollo.MutationResult<UpdateUserMembershipMutation>;
export type UpdateUserMembershipMutationOptions = Apollo.BaseMutationOptions<UpdateUserMembershipMutation, UpdateUserMembershipMutationVariables>;
export const CitiesDocument = gql`
    query cities {
  cities {
    city_id
    province_id
    province
    type
    city_name
    postal_code
  }
}
    `;

/**
 * __useCitiesQuery__
 *
 * To run a query within a React component, call `useCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCitiesQuery(baseOptions?: Apollo.QueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, options);
      }
export function useCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CitiesQuery, CitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CitiesQuery, CitiesQueryVariables>(CitiesDocument, options);
        }
export type CitiesQueryHookResult = ReturnType<typeof useCitiesQuery>;
export type CitiesLazyQueryHookResult = ReturnType<typeof useCitiesLazyQuery>;
export type CitiesQueryResult = Apollo.QueryResult<CitiesQuery, CitiesQueryVariables>;
export const CouponDocument = gql`
    query coupon($code: String!) {
  coupon(code: $code) {
    id
    code
    referrer
    createdAt
    redeemedAt
    user {
      id
      email
      fullname
    }
    pricing {
      id
      duration
      plan {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useCouponQuery__
 *
 * To run a query within a React component, call `useCouponQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useCouponQuery(baseOptions: Apollo.QueryHookOptions<CouponQuery, CouponQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CouponQuery, CouponQueryVariables>(CouponDocument, options);
      }
export function useCouponLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CouponQuery, CouponQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CouponQuery, CouponQueryVariables>(CouponDocument, options);
        }
export type CouponQueryHookResult = ReturnType<typeof useCouponQuery>;
export type CouponLazyQueryHookResult = ReturnType<typeof useCouponLazyQuery>;
export type CouponQueryResult = Apollo.QueryResult<CouponQuery, CouponQueryVariables>;
export const CouponsDocument = gql`
    query coupons($page: Int!, $query: String) {
  coupons(page: $page, query: $query) {
    id
    code
    referrer
    createdAt
    redeemedAt
    user {
      id
      email
      fullname
    }
    pricing {
      id
      duration
      plan {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useCouponsQuery__
 *
 * To run a query within a React component, call `useCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouponsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useCouponsQuery(baseOptions: Apollo.QueryHookOptions<CouponsQuery, CouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CouponsQuery, CouponsQueryVariables>(CouponsDocument, options);
      }
export function useCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CouponsQuery, CouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CouponsQuery, CouponsQueryVariables>(CouponsDocument, options);
        }
export type CouponsQueryHookResult = ReturnType<typeof useCouponsQuery>;
export type CouponsLazyQueryHookResult = ReturnType<typeof useCouponsLazyQuery>;
export type CouponsQueryResult = Apollo.QueryResult<CouponsQuery, CouponsQueryVariables>;
export const InvoiceDocument = gql`
    query invoice($id: ID!) {
  invoice(id: $id) {
    id
    originalAmount
    finalAmount
    status
    method
    expiredAt
    createdAt
    lastChecked
    plan {
      name
    }
  }
}
    `;

/**
 * __useInvoiceQuery__
 *
 * To run a query within a React component, call `useInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceQuery(baseOptions: Apollo.QueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options);
      }
export function useInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceQuery, InvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceQuery, InvoiceQueryVariables>(InvoiceDocument, options);
        }
export type InvoiceQueryHookResult = ReturnType<typeof useInvoiceQuery>;
export type InvoiceLazyQueryHookResult = ReturnType<typeof useInvoiceLazyQuery>;
export type InvoiceQueryResult = Apollo.QueryResult<InvoiceQuery, InvoiceQueryVariables>;
export const InvoicesDocument = gql`
    query invoices {
  invoices {
    id
    originalAmount
    finalAmount
    status
    method
    expiredAt
    createdAt
    plan {
      name
    }
    duration
  }
}
    `;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
      }
export function useInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
        }
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<typeof useInvoicesLazyQuery>;
export type InvoicesQueryResult = Apollo.QueryResult<InvoicesQuery, InvoicesQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    fullname
    email
    phone
    apiKey
    membership {
      plan {
        name
      }
      expiredAt
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MemberDocument = gql`
    query member($id: ID!) {
  member(id: $id) {
    id
    fullname
    email
    phone
    apiKey
    membership {
      plan {
        id
        name
        pricing {
          price
          duration
        }
      }
      expiredAt
    }
    createdAt
    invoices {
      id
      originalAmount
      finalAmount
      status
      method
      expiredAt
      createdAt
      lastChecked
      plan {
        name
      }
    }
  }
  plans {
    id
    name
    pricing {
      price
      duration
    }
    description
  }
}
    `;

/**
 * __useMemberQuery__
 *
 * To run a query within a React component, call `useMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMemberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMemberQuery(baseOptions: Apollo.QueryHookOptions<MemberQuery, MemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MemberQuery, MemberQueryVariables>(MemberDocument, options);
      }
export function useMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MemberQuery, MemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MemberQuery, MemberQueryVariables>(MemberDocument, options);
        }
export type MemberQueryHookResult = ReturnType<typeof useMemberQuery>;
export type MemberLazyQueryHookResult = ReturnType<typeof useMemberLazyQuery>;
export type MemberQueryResult = Apollo.QueryResult<MemberQuery, MemberQueryVariables>;
export const MembersDocument = gql`
    query members($query: String) {
  members(query: $query) {
    id
    fullname
    email
    phone
    apiKey
    membership {
      plan {
        name
      }
      expiredAt
    }
    createdAt
  }
}
    `;

/**
 * __useMembersQuery__
 *
 * To run a query within a React component, call `useMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMembersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useMembersQuery(baseOptions?: Apollo.QueryHookOptions<MembersQuery, MembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembersQuery, MembersQueryVariables>(MembersDocument, options);
      }
export function useMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembersQuery, MembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembersQuery, MembersQueryVariables>(MembersDocument, options);
        }
export type MembersQueryHookResult = ReturnType<typeof useMembersQuery>;
export type MembersLazyQueryHookResult = ReturnType<typeof useMembersLazyQuery>;
export type MembersQueryResult = Apollo.QueryResult<MembersQuery, MembersQueryVariables>;
export const NotesDocument = gql`
    query notes($linkedId: String!) {
  notes(linkedId: $linkedId) {
    id
    title
    content
  }
}
    `;

/**
 * __useNotesQuery__
 *
 * To run a query within a React component, call `useNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotesQuery({
 *   variables: {
 *      linkedId: // value for 'linkedId'
 *   },
 * });
 */
export function useNotesQuery(baseOptions: Apollo.QueryHookOptions<NotesQuery, NotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotesQuery, NotesQueryVariables>(NotesDocument, options);
      }
export function useNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotesQuery, NotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotesQuery, NotesQueryVariables>(NotesDocument, options);
        }
export type NotesQueryHookResult = ReturnType<typeof useNotesQuery>;
export type NotesLazyQueryHookResult = ReturnType<typeof useNotesLazyQuery>;
export type NotesQueryResult = Apollo.QueryResult<NotesQuery, NotesQueryVariables>;
export const PlansDocument = gql`
    query plans {
  plans {
    id
    name
    pricing {
      id
      price
      duration
    }
    description
  }
  me {
    id
    fullname
    email
    phone
    apiKey
    membership {
      plan {
        name
      }
      expiredAt
    }
  }
}
    `;

/**
 * __usePlansQuery__
 *
 * To run a query within a React component, call `usePlansQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlansQuery({
 *   variables: {
 *   },
 * });
 */
export function usePlansQuery(baseOptions?: Apollo.QueryHookOptions<PlansQuery, PlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
      }
export function usePlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlansQuery, PlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlansQuery, PlansQueryVariables>(PlansDocument, options);
        }
export type PlansQueryHookResult = ReturnType<typeof usePlansQuery>;
export type PlansLazyQueryHookResult = ReturnType<typeof usePlansLazyQuery>;
export type PlansQueryResult = Apollo.QueryResult<PlansQuery, PlansQueryVariables>;
export const SimplepPlansDocument = gql`
    query simplepPlans {
  plans {
    id
    name
    pricing {
      id
      price
      duration
    }
    description
  }
}
    `;

/**
 * __useSimplepPlansQuery__
 *
 * To run a query within a React component, call `useSimplepPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimplepPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimplepPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useSimplepPlansQuery(baseOptions?: Apollo.QueryHookOptions<SimplepPlansQuery, SimplepPlansQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimplepPlansQuery, SimplepPlansQueryVariables>(SimplepPlansDocument, options);
      }
export function useSimplepPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimplepPlansQuery, SimplepPlansQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimplepPlansQuery, SimplepPlansQueryVariables>(SimplepPlansDocument, options);
        }
export type SimplepPlansQueryHookResult = ReturnType<typeof useSimplepPlansQuery>;
export type SimplepPlansLazyQueryHookResult = ReturnType<typeof useSimplepPlansLazyQuery>;
export type SimplepPlansQueryResult = Apollo.QueryResult<SimplepPlansQuery, SimplepPlansQueryVariables>;
export const TemplatesDocument = gql`
    query templates {
  templates {
    id
    title
    text
  }
}
    `;

/**
 * __useTemplatesQuery__
 *
 * To run a query within a React component, call `useTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<TemplatesQuery, TemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TemplatesQuery, TemplatesQueryVariables>(TemplatesDocument, options);
      }
export function useTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TemplatesQuery, TemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TemplatesQuery, TemplatesQueryVariables>(TemplatesDocument, options);
        }
export type TemplatesQueryHookResult = ReturnType<typeof useTemplatesQuery>;
export type TemplatesLazyQueryHookResult = ReturnType<typeof useTemplatesLazyQuery>;
export type TemplatesQueryResult = Apollo.QueryResult<TemplatesQuery, TemplatesQueryVariables>;
export const UserDocument = gql`
    query user {
  user {
    id
    fullname
    email
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const AuthCheckDocument = gql`
    subscription authCheck {
  authCheck {
    for
    loggedOut
  }
}
    `;

/**
 * __useAuthCheckSubscription__
 *
 * To run a query within a React component, call `useAuthCheckSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAuthCheckSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthCheckSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAuthCheckSubscription(baseOptions?: Apollo.SubscriptionHookOptions<AuthCheckSubscription, AuthCheckSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AuthCheckSubscription, AuthCheckSubscriptionVariables>(AuthCheckDocument, options);
      }
export type AuthCheckSubscriptionHookResult = ReturnType<typeof useAuthCheckSubscription>;
export type AuthCheckSubscriptionResult = Apollo.SubscriptionResult<AuthCheckSubscription>;
export const MessageSentDocument = gql`
    subscription messageSent {
  messageSent {
    for
    recipient
    text
  }
}
    `;

/**
 * __useMessageSentSubscription__
 *
 * To run a query within a React component, call `useMessageSentSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMessageSentSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageSentSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMessageSentSubscription(baseOptions?: Apollo.SubscriptionHookOptions<MessageSentSubscription, MessageSentSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MessageSentSubscription, MessageSentSubscriptionVariables>(MessageSentDocument, options);
      }
export type MessageSentSubscriptionHookResult = ReturnType<typeof useMessageSentSubscription>;
export type MessageSentSubscriptionResult = Apollo.SubscriptionResult<MessageSentSubscription>;