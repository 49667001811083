import React from 'react'
import Sidebar from "../components/Sidebar";
import { createContext, useReducer } from "react";

const initialState = {
  sidebarOpen: false,
  active: "Dashboard"
};

export type State = {
  sidebarOpen: boolean;
  active: "Dashboard" | "Membership" | "Payment" | "Integration" | "Settings";
};

export type Action = { type: "toggle" } | { type: "close" };

export const SidebarContext = createContext<{
  state: typeof initialState;
  dispatch: (action: Action) => void;
}>({
  state: initialState,
  dispatch: () => {}
});

const sidebarReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "toggle":
      return {
        ...state,
        sidebarOpen: !state.sidebarOpen
      };
    case "close":
      return {
        ...state,
        sidebarOpen: false
      };
    default:
      return state;
  }
};

const SidebarProvider: React.FC<{ active: State["active"] }> = ({
  children,
  active
}) => {
  const [state, dispatch] = useReducer(sidebarReducer, {
    ...initialState,
    active
  });
  const value = { state, dispatch };

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
};

const Layout: React.FC<{ active: State["active"] }> = ({
  children,
  active
}) => {
  return (
    <div className="relative flex w-full h-full min-h-screen font-sans antialiased">
      <SidebarProvider active={active}>
        <Sidebar />
        <div className="flex-1 overflow-auto bg-gray-100">{children}</div>
      </SidebarProvider>
    </div>
  );
};

export default Layout;
