import React from 'react'
import { useLoginMutation } from "@wa/graphql/generated";
import classnames from "classnames";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useAuthorizedRoute } from "../components/hooks/useAuthorizedRoute";
import Separator from "../components/Separator";

interface FormData {
  email: string;
  password: string;
}

export const Login = () => {
  useAuthorizedRoute();
  const { register, handleSubmit, errors } = useForm<FormData>();
  const [login] = useLoginMutation();

  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const onSubmit = async (values: Record<string, any>) => {
    try {
      const { email, password } = values;
      setLoading(true);
      setError(null);

      const { data } = await login({
        variables: {
          email,
          password
        }
      });
      if (data?.login) {
        window.location.href = "/";
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log(err);

      setLoading(false);
      setError(err.message.replace(/(.*)\:/, ""));
    }
  };

  return (
    <div className="flex items-center min-h-screen antialiased bg-gray-200">
      {/* <Head>
        <title>Member Login</title>
      </Head> */}
      <div className="flex flex-col items-center justify-center max-w-xl px-2 mx-auto w-[21rem]">
        <div className="flex items-center justify-center py-4">
          <img className="w-8 h-8" src="/icon.png" alt="Whatsapp CS Logo" />
          <h1 className="pl-2 text-xl font-semibold">WhatsApp CS</h1>
        </div>
        <form
          className="flex flex-col w-full overflow-hidden bg-white rounded-lg shadow-md"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h3 className="px-5 pt-4 text-xl font-semibold text-gray-900">
            Member Login
          </h3>
          <Separator />
          {error && (
            <span className="px-3 py-2 mx-5 mb-3 text-sm text-red-700 bg-red-200 rounded">
              {error}
            </span>
          )}
          <div className="grid gap-4 px-5 pb-4">
            <label className="block">
              <span className="text-sm font-medium text-gray-700">
                Email Address
              </span>
              <input
                className="block w-full mt-1 form-input"
                placeholder="bob@gmail.com"
                type="email"
                name="email"
                required
                ref={register({ required: true })}
              />
            </label>
            {errors.email && (
              <span className="px-3 py-2 text-sm text-red-700">
                {errors.email?.message}
              </span>
            )}
            <label className="block">
              <span className="text-sm font-medium text-gray-700">
                Password
              </span>
              <input
                className="block w-full mt-1 form-input"
                placeholder="*********"
                type="password"
                name="password"
                required
                ref={register({ required: true })}
              />
            </label>
            {errors.password && (
              <span className="px-3 py-2 text-sm text-red-700">
                {errors.password?.message}
              </span>
            )}
            <div className="text-right">
              <Link
                to="/password-reset"
                className="px-2 text-sm font-medium text-blue-600"
              >
                Forgot Password
              </Link>
            </div>
            <button
              className={classnames(
                "w-full text-white font-semibold rounded shadow px-4 py-2",
                {
                  "bg-blue-600": !isLoading,
                  "bg-gray-600": isLoading
                }
              )}
              disabled={isLoading}
              type="submit"
            >
              Login
            </button>
          </div>
        </form>
        <div className="pt-4 pb-1 mx-auto">
          <span className="text-sm font-medium text-gray-700">
            Need an account?
          </span>
          <a
            className="px-2 text-sm font-semibold text-blue-600"
            href="/signup"
          >
            Register here
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;
