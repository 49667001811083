import React from "react";
import { useRequestResetPasswordMutation } from "@wa/graphql/generated";
import classnames from "classnames";
import { useForm } from "react-hook-form";
import Separator from "../../components/Separator";
import { formatError } from "../../utils/string";

interface FormData {
  email: string;
}

const PasswordResetPage = () => {
  const { register, handleSubmit, errors } = useForm<FormData>();

  const [
    resetPassword,
    { error, loading, data }
  ] = useRequestResetPasswordMutation({
    onError: (error) => console.log(error)
  });

  const onSubmit = async (values: FormData) => {
    await resetPassword({
      variables: {
        email: values.email
      }
    });
  };

  return (
    <div className="flex items-center min-h-screen antialiased bg-gray-200">
      {/* <Head>
        <title>Reset your password</title>
      </Head> */}
      <div className="flex flex-col items-center justify-center max-w-xl px-2 mx-auto w-84">
        <div className="flex items-center justify-center py-4">
          <img className="w-8 h-8" src="/icon.png" alt="Whatsapp CS Logo" />
          <h1 className="pl-2 text-xl font-semibold">WhatsApp CS</h1>
        </div>
        <form
          className="flex flex-col w-full overflow-hidden bg-white rounded-lg shadow-md"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h3 className="px-5 pt-4 text-xl font-semibold text-gray-900">
            Reset Password
          </h3>
          <Separator />

          {(data && (
            <div className="px-4 py-3">
              <div className="px-3 py-2 mb-6 text-center text-green-700 bg-green-200 rounded">
                Password Reset Link has been sent, check your inbox or your spam
                folder.
              </div>
            </div>
          )) ?? (
            <>
              {error && (
                <span className="px-3 py-2 mx-5 mb-3 text-sm text-red-700 bg-red-200 rounded">
                  {formatError(error.message)}
                </span>
              )}
              <div className="grid gap-4 px-5 pb-4">
                <label className="block">
                  <span className="text-sm font-medium text-gray-700">
                    Email Address
                  </span>
                  <input
                    className="block w-full mt-1 form-input"
                    placeholder="bob@gmail.com"
                    name="email"
                    type="email"
                    required
                    ref={register}
                  />
                  {errors.email && (
                    <span className="text-sm text-red-700">
                      {errors.email?.message}
                    </span>
                  )}
                </label>
                <button
                  className={classnames(
                    "w-full text-white font-semibold rounded shadow px-4 py-2",
                    {
                      "bg-blue-600": !loading,
                      "bg-gray-600": loading
                    }
                  )}
                  disabled={loading}
                  type="submit"
                >
                  Send Password Reset Link
                </button>
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default PasswordResetPage;
