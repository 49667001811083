import React from 'react'
type Props = {
  title: string;
  content?: JSX.Element | string | undefined; 
};

export const InfoRow: React.FC<Props> = ({ title, content, children }) => {
  return (
    <>
      <div className="col-span-2 text-sm text-gray-600">{title}</div>
      <div className="col-span-3 mb-3 text-sm font-medium text-gray-800 md:mb-0" aria-label={title}>
        {content ? content : children}
      </div>
    </>
  );
};
