import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

const menus = [
  {
    name: "User",
    href: "/admin"
  },
  {
    name: "Membership",
    href: "/admin/membership"
  },
  {
    name: "Voucher",
    href: "/admin/voucher"
  }
];

type Props = {
  activeMenu: "User" | "Membership" | "Voucher";
};

const AdminLayout: React.FC<Props> = ({ children, activeMenu }) => {
  return (
    <div className="font-sans antialiased">
      {/* <Head>
        <title>Admin Dashboard</title>
      </Head> */}
      <div className="px-4 pt-8 pb-32 bg-gray-800 md:px-16 ">
        <div className="max-w-4xl mx-auto">
          <Link
            to="/"
            className="flex items-center pb-2 font-medium text-blue-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              className="w-5 h-5 cursor-pointer fill-current"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
            </svg>
            <span className="pl-1">Kembali ke Member Area</span>
          </Link>
          <h1 className="text-3xl font-medium text-white">Admin Dashboard</h1>

          <div className="flex py-4">
            {menus.map((menu) => (
              <Link to={menu.href} key={menu.name}>
                <div
                  className={classnames(
                    "text-gray-400 font-medium rounded-lg tracking-wide cursor-pointer hover:text-blue-100 px-4 py-2 mr-2",
                    {
                      "bg-gray-900 text-blue-100 hover:bg-gray-900":
                        menu.name === activeMenu
                    }
                  )}
                >
                  {menu.name}
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="max-w-4xl mx-auto">
        <div className="mb-6 -mt-16 overflow-hidden bg-white shadow-md sm:rounded-md">
          {children}
        </div>
      </div>
      <div className="py-4"></div>
    </div>
  );
};

export default AdminLayout;
