import React from 'react'
import { useMeQuery } from "@wa/graphql/generated";
import Header from "../../components/Header";
import Layout from "../../components/Layout";
import Card from "../../components/Card";
import Skeleton from "react-loading-skeleton";
import { isEligible } from "@wa/extension/src/utils/Membership";

const IntegrationPage = () => {
  const { data } = useMeQuery();

  if (!data?.me) return null;

  return (
    <Layout active="Integration">
      {/* <Head>
        <title>Integration</title>
      </Head> */}
      <Header title="Integration" />

      <div className="max-w-2xl py-4 mx-auto">
        {data && !isEligible(data.me.membership.plan, "developer") && (
          <div className="col-span-1 px-3 py-2 mb-4 text-gray-800 bg-yellow-100 border border-yellow-500 rounded md:col-span-3">
            <p>This feature only available for developer membership</p>
          </div>
        )}
        <Card title="API Access" className="mb-8 overflow-hidden">
          <div className="px-4 pb-5">
            <p className="pb-2 text-sm font-medium">API Key</p>
            <div className="px-2 py-1 text-gray-900 bg-gray-100 border-2 border-gray-400 rounded">
              {data?.me.apiKey ?? <Skeleton />}
            </div>
          </div>
        </Card>
        <Card title="Send a message" className="overflow-hidden">
          <div className="flex flex-col px-4 pb-3">
            <p className="text-sm">
              To send a message through extension, make sure you open the
              extension and activate Integration option.
            </p>

            <p className="pt-6 pb-2 font-medium">Request</p>
            <div className="flex overflow-x-auto border">
              <table className="flex-1 mt-2 text-sm table-auto">
                <thead className="text-left border-b border-gray-200">
                  <tr>
                    <th className="px-4 py-2">Method</th>
                    <th className="px-4 py-2">URL</th>
                  </tr>
                </thead>
                <tbody className="text-gray-700">
                  <tr className="whitespace-no-wrap">
                    <td className="px-4 py-3">POST</td>
                    <td className="px-4 py-3">https://member.chatwa.id/send</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p className="pt-6 pb-2 font-medium">Headers</p>
            <div className="flex overflow-x-auto border">
              <table className="flex-1 mt-2 text-sm table-auto">
                <thead className="text-left border-b border-gray-200">
                  <tr>
                    <th className="px-4 py-2">Key</th>
                    <th className="px-4 py-2">Value</th>
                  </tr>
                </thead>
                <tbody className="text-gray-700">
                  <tr className="whitespace-no-wrap">
                    <td className="px-4 py-3">Content-Type</td>
                    <td className="px-4 py-3">
                      application/x-www-form-urlencoded
                    </td>
                  </tr>
                  <tr className="whitespace-no-wrap">
                    <td className="px-4 py-3">X-API-KEY</td>
                    <td className="px-4 py-3">(Your API Key)</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p className="pt-6 pb-2 font-medium">Body</p>
            <div className="flex overflow-x-auto border">
              <table className="flex-1 mt-2 overflow-x-scroll text-sm table-auto">
                <thead className="text-left border-b border-gray-200">
                  <tr>
                    <th className="px-4 py-2">Key</th>
                    <th className="px-4 py-2">Description</th>
                  </tr>
                </thead>
                <tbody className="text-gray-700">
                  <tr className="whitespace-no-wrap">
                    <td className="px-4 py-3">recipient</td>
                    <td className="px-4 py-3">
                      Recipient phone number, including country code (eg:
                      6281898765432)
                    </td>
                  </tr>
                  <tr className="whitespace-no-wrap">
                    <td className="px-4 py-3">text</td>
                    <td className="px-4 py-3">Message to send</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Card>
      </div>
    </Layout>
  );
};

export default IntegrationPage;
