import React from 'react'
import classnames from "classnames";
import Separator from "./Separator";

type CardProps = {
  title: string;
  renderfooter?: Function;
  className?: string;
};

type CardBaseProps = {
  className: string;
};

export const CardBase: React.FC<CardBaseProps> = props => {
  const { className } = props;

  return (
    <div
      {...props}
      className={classnames("bg-white shadow rounded", className)}
    ></div>
  );
};

export const CardTitle: React.FC<{ title: string }> = ({ title }) => {
  return (
    <div className="px-4 pt-3">
      <span className="font-medium">{title}</span>
    </div>
  );
};

const Card: React.FC<CardProps> = props => {
  const { title, children, renderfooter, className } = props;

  return (
    <CardBase
      className={classnames("col-span-1", className)}
      aria-label={title}
    >
      <CardTitle title={title} />
      <Separator />
      {children}
      {renderfooter && (
        <>
          <Separator />
          {renderfooter()}
        </>
      )}
    </CardBase>
  );
};

export default Card;
