import React from "react";
import { useInvoicesQuery } from "@wa/graphql/generated";
import { formatWithOptions } from "date-fns/fp";
import { id } from "date-fns/locale";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import Header from "../../components/Header";
import { InvoiceStatusLabel } from "../../components/InvoiceStatusLabel";
import Layout from "../../components/Layout";
import { formatEnum } from "../../utils/enum";
import { formatNumber } from "../../utils/number";
import { capitalize } from "../../utils/string";

const PaymentPage = () => {
  const { data, loading } = useInvoicesQuery();

  return (
    <Layout active="Payment">
      {/* <Head>
        <title>Daftar Invoice</title>
      </Head> */}
      <Header title="Payment" />
      <div className="grid gap-4 px-4 py-4">
        <Card title="Payment List" className="overflow-hidden">
          <div className="flex overflow-auto">
            <table className="w-full text-sm table-auto">
              <thead className="border-b border-gray-200">
                <tr>
                  <th className="px-4 py-2">Date</th>
                  <th className="px-4 py-2">Product Name</th>
                  <th className="px-4 py-2">Payment Method</th>
                  <th className="px-4 py-2 text-right">Amount</th>
                  <th className="px-4 py-2">Status</th>
                  <th className="px-4 py-2"></th>
                </tr>
              </thead>
              <tbody className="text-gray-700">
                {data?.invoices?.map((invoice) => (
                  <tr
                    className="whitespace-no-wrap hover:bg-gray-200"
                    key={invoice.id}
                  >
                    <td className="px-4 py-3 text-center">
                      {formatWithOptions(
                        { locale: id },
                        "d MMM YYY"
                      )(new Date(invoice.createdAt))}
                    </td>
                    <td className="px-4 py-3 text-center">
                      WhatsApp CS ({capitalize(invoice.plan.name)})
                    </td>
                    <td className="px-4 py-3 text-center">
                      {invoice.method
                        ? capitalize(formatEnum(invoice.method))
                        : "-"}
                    </td>
                    <td className="px-4 py-3 font-semibold text-right text-gray-800">
                      Rp {formatNumber(invoice.finalAmount)}
                    </td>
                    <td className="px-4 py-3 text-sm text-center">
                      <InvoiceStatusLabel status={invoice.status} />
                    </td>
                    <td className="px-4 py-3 text-sm text-center">
                      <Link
                        to={`/payment/${invoice.id}`}
                        className="px-2 py-2 text-blue-600 rounded text-semibold"
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
              {data?.invoices?.length === 0 && (
                <div className="px-6 pb-4 text-center text-gray-700">
                  You don't have any invoice
                  <br />
                  Invoices will appear here
                </div>
              )}
            </table>
          </div>
          {loading && (
            <div className="pb-3">
              {new Array(3).map((i) => (
                <div className="px-6 pt-3" key={i}>
                  <Skeleton />
                </div>
              ))}
            </div>
          )}
        </Card>
      </div>
    </Layout>
  );
};

export default PaymentPage;
