import React from 'react'
import { CheckSolid, XSolid } from "@graywolfai/react-heroicons";
import classnames from "classnames";
import { Link } from "react-router-dom";


import { formatNumber } from "../utils/number";

type PricingCardProps = {
  name: string;
  price: number;
  primary?: boolean;
  description?: string;
  renderFooter?: (name: string) => JSX.Element;
};

const PricingCard: React.FC<PricingCardProps> = ({
  name,
  price,
  description,
  children,
  primary = false,
  renderFooter
}) => {
  return (
    <div className="col-span-1">
      <div
        className={classnames("bg-white rounded", {
          "border-2 border-blue-400 shadow-xl": primary,
          "border-none shadow": !primary
        })}
      >
        <div className="relative px-4 py-3">
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-semibold leading-tight tracking-wider text-gray-800 uppercase">
              {name}
            </h3>
            {primary && (
              <span className="px-2 py-1 text-xs font-medium text-white bg-blue-500 rounded-md">
                Populer
              </span>
            )}
          </div>
          <div className="flex items-end pt-4 text-gray-900">
            <span className="flex items-center text-2xl font-bold leading-none">
              <span className="text-sm">Rp</span>
              <span className="pl-1">{formatNumber(price)}</span>
            </span>
            <span className="pl-1 leading-none">/ month</span>
          </div>
          {description && (
            <p className="pt-3 text-sm text-gray-700">{description}</p>
          )}
        </div>
        <div className="py-3">
          <div className="border-b border-gray-300"></div>
        </div>
        <div className="grid gap-4 px-4 pb-3 grid-col-1">{children}</div>
        {renderFooter ? (
          renderFooter(name)
        ) : (
          <>
            <div className="pb-3">
              <div className="border-b border-gray-300"></div>
            </div>
            <div className="px-4 pb-3">
              <Link
                to={`/signup/${name.toLowerCase()}`}
                className={classnames(
                  "block text-center font-semibold rounded py-2",
                  {
                    "bg-blue-600 text-blue-100": primary,
                    "bg-blue-200 text-blue-700": !primary
                  }
                )}
              >
                {price > 0 ? "Beli Sekarang" : "Coba Gratis"}
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

type PricingFeatureListItemProps = {
  description: string;
  available: boolean;
};

const PricingFeatureListItem: React.FC<PricingFeatureListItemProps> = ({
  description,
  available = true
}) => {
  return (
    <div className="flex items-center">
      <div
        className={classnames("rounded-full p-1", {
          "bg-green-200": available,
          "bg-red-200": !available
        })}
      >
        {available ? (
          <CheckSolid className="w-4 h-4 text-green-800 fill-current" />
        ) : (
          <XSolid className="w-4 h-4 text-red-800 fill-current" />
        )}
      </div>
      <span className="pl-3 text-sm">{description}</span>
    </div>
  );
};

export { PricingCard, PricingFeatureListItem };
