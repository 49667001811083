import { ApolloClient, InMemoryCache, HttpLink, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

const errorLink = onError(
  ({ networkError, graphQLErrors, forward, operation }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        const { message, locations, path } = err;
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );

        if (err.message === "Not authorized") {
          window.location.href = "/";
        }

        if (err?.extensions?.code) {
          switch (err.extensions.code) {
            case "UNAUTHENTICATED":
              window.location.href = "/login";
              return;

            default:
              return;
          }
        }
      }
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
    forward(operation);
  }
);

const httpLink = new HttpLink({
  uri: "/graphql",
  credentials: "include"
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, httpLink])
});
