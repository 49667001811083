import { usePlansQuery, useRedeemCouponMutation } from "@wa/graphql/generated";
import classnames from "classnames";
import React from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Header from "../../components/Header";
import Layout from "../../components/Layout";

const RedeemPage = () => {
  const { register, handleSubmit } = useForm<{
    code: string;
  }>({
    defaultValues: {
      code: ""
    }
  });
  const [mutate, { data, loading, error }] = useRedeemCouponMutation();

  useEffect(() => {
    if (data?.redeemCoupon === true) {
      window.location.href = "/";
    }
  }, [data]);

  return (
    <Layout active="Membership">
      {/* <Head>
        <title>Plans</title>
      </Head> */}
      <Header title="Redeem Voucher" />

      <form
        className="max-w-3xl px-2 py-4 mx-auto mt-4 bg-white rounded shadow md:px-6"
        onSubmit={handleSubmit(({ code }) => {
          mutate({
            variables: {
              code
            }
          });
        })}
      >
        <h2 className="py-1">Enter you voucher code here</h2>
        <input type="text" className="form-input" name="code" ref={register} />

        <button
          className={classnames(
            "w-full text-white font-semibold rounded shadow px-4 py-2 mt-4",
            {
              "bg-blue-600": !loading,
              "bg-gray-600 cursor-not-allowed": loading
            }
          )}
          disabled={loading}
          type="submit"
        >
          Redeem
        </button>
        {error && (
          <span className="block px-3 py-2 mt-3 text-sm text-red-700 bg-red-200 rounded">
            {error.message}
          </span>
        )}
      </form>
    </Layout>
  );
};

export default RedeemPage;
