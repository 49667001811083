import { useUserQuery } from "@wa/graphql/generated";
import { useEffect } from "react";

export function useAuthorizedRoute() {
  const { data, loading } = useUserQuery();

  useEffect(() => {
    if (!loading && data?.user !== null) {
      window.location.href = "/";
    }
  }, [data, loading]);
}
