import { useMeQuery } from "@wa/graphql/generated";

const useUser = () => {
  const data = useMeQuery({
    pollInterval: 60 * 1000,
  });

  return data;
};

export default useUser;
