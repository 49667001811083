import React from 'react'
import { useChangePasswordMutation } from "@wa/graphql/generated";
import classnames from "classnames";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { CardBase } from "../../components/Card";
import { FormInput } from "../../components/FormInput";
import Header from "../../components/Header";
import useUser from "../../components/hooks/useUser";
import Layout from "../../components/Layout";
import Separator from "../../components/Separator";

type FormData = {
  fullname: string;
  email: string;
  phone: string;
  duration: number;
  password: string;
};

const SettingsPage = () => {
  return (
    <Layout active="Settings">
      {/* <Head>
        <title>Settings</title>
      </Head> */}
      <Header title="Settings" />

      <div className="grid grid-cols-2 gap-4 pt-3 pb-8 md:grid-cols-3">
        <AccountSettings />
        <div className="col-span-2 md:col-span-3">
          <Separator padding="pt-3" borderColor="border-gray-300" />
        </div>
        <ChangePassword />
      </div>
    </Layout>
  );
};

const AccountSettings: React.FC = () => {
  const { register, errors } = useForm<FormData>();
  const { data } = useUser();

  return (
    <>
      <div className="hidden px-8 pt-3 font-medium md:block md:col-span-1">
        <h2>Account Settings</h2>
      </div>
      <div className="col-span-2 px-4 pt-3">
        <CardBase className="rounded-md">
          <div className="md:hidden">
            <h2 className="px-4 pt-3 font-medium">Account Settings</h2>
            <Separator padding="pt-3" />
          </div>
          <div className="px-4 py-3" aria-label="Account Settings">
            <FormInput
              label="Full Name"
              type="text"
              name="fullname"
              error={errors.fullname}
              defaultValue={data?.me?.fullname}
              ref={register({ required: true })}
              disabled={true}
            />
            <FormInput
              label="Phone Number"
              type="text"
              name="phone"
              error={errors.phone}
              defaultValue={data?.me?.phone}
              ref={register({ required: true })}
              disabled={true}
            />
            <FormInput
              label="Email Address"
              type="email"
              name="email"
              error={errors.email}
              defaultValue={data?.me?.email}
              ref={register({ required: true })}
              disabled={true}
            />
          </div>
        </CardBase>
      </div>
    </>
  );
};

interface ChangePasswordFormData {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

const ChangePassword: React.FC = () => {
  const errorMessages = {
    password: {
      required: "Password cannot be empty",
      min: "Password must be more than 6 characters",
      match: "Password is not match"
    }
  };

  const { register, handleSubmit, errors } = useForm<ChangePasswordFormData>({
    validationSchema: yup.object().shape({
      oldPassword: yup.string()
        .min(6, errorMessages.password.min)
        .required(errorMessages.password.required),
      newPassword: yup.string()
        .min(6, errorMessages.password.min)
        .required(errorMessages.password.required)
        .oneOf([yup.ref("confirmNewPassword")], errorMessages.password.match),
      confirmNewPassword: yup.string()
        .min(6, errorMessages.password.min)
        .required(errorMessages.password.required)
        .oneOf([yup.ref("newPassword")], errorMessages.password.match)
    })
  });
  const [
    changePassword,
    { data, loading, error }
  ] = useChangePasswordMutation();

  const onSubmit = async (values: ChangePasswordFormData) => {
    const { oldPassword, newPassword } = values;

    await changePassword({
      variables: {
        oldPassword,
        newPassword
      }
    });
  };

  return (
    <>
      <div className="hidden px-8 pt-3 font-medium md:block md:col-span-1">
        <h2>Change Password</h2>
      </div>
      <form className="col-span-2 px-4 pt-3" onSubmit={handleSubmit(onSubmit)}>
        <CardBase className="rounded-md">
          <div className="md:hidden">
            <h2 className="px-4 pt-3 font-medium">Change Password</h2>
            <Separator padding="pt-3" />
          </div>
          <div className="px-4 py-3" aria-label="Change Password">
            {data?.changePassword && (
              <div className="px-3 py-2 mb-4 text-sm text-green-700 bg-green-200 rounded">
                Password changed successfully
              </div>
            )}
            {error && (
              <div className="px-3 py-2 mb-4 text-sm text-red-700 bg-red-200 rounded">
                {error.message.replace(/(.*)\:/, "")}
              </div>
            )}
            <FormInput
              label="Current Password"
              placeholder="********"
              name="oldPassword"
              type="password"
              error={errors.oldPassword}
              ref={register({ required: true })}
            />
            <FormInput
              label="New Password"
              placeholder="********"
              name="newPassword"
              type="password"
              error={errors.newPassword}
              ref={register({ required: true })}
            />
            <FormInput
              label="Confirm New Password"
              placeholder="********"
              name="confirmNewPassword"
              type="password"
              error={errors.confirmNewPassword}
              ref={register({ required: true })}
            />
            <button
              className={classnames(
                "text-sm text-white font-semibold rounded shadow px-4 py-2",
                {
                  "bg-blue-600": !loading,
                  "bg-gray-600": loading
                }
              )}
              disabled={loading}
              type="submit"
            >
              Update
            </button>
          </div>
        </CardBase>
      </form>
    </>
  );
};

export default SettingsPage;
