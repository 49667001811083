import { useCouponQuery } from "@wa/graphql/generated";
import { format } from "date-fns";
import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import AdminLayout from "../../../components/Admin/AdminLayout";

const AdminCouponDetailsPage = () => {
  const { code } = useParams<{
    code: string;
  }>();
  const { data, loading  } = useCouponQuery({
    variables: {
      code
    }
  });

  return (
    <AdminLayout activeMenu="Voucher">
      <div className="bg-white rounded shadow">
        <div className="flex items-center justify-between px-4">
          <h2 className="py-3 text-xl font-semibold">Voucher Info</h2>
        </div>
        {loading ? (
          <div className="pb-4 text-center">Loading...</div>
        ) : (
          <div className="px-6 py-4 space-y-6 bg-white border-b border-gray-300 rounded shadow">
            <div className="flex items-center justify-between">
              <div className="text-sm text-gray-700">Code</div>
              <div className="font-medium text-gray-900 uppercase">
                {data?.coupon.code}
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="text-sm text-gray-700">Plan</div>
              <div className="font-medium text-gray-900 capitalize">
                {data?.coupon.pricing?.plan?.name}
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="text-sm text-gray-700">Duration</div>
              <div className="font-medium text-gray-900 capitalize">
                {data?.coupon.pricing?.duration} bln
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div className="text-sm text-gray-700">Status</div>
              <span className="px-2 py-1 text-xs text-white bg-yellow-500 rounded-full">
                {data?.coupon?.redeemedAt ? "Redeemed" : "Unredeemed"}
              </span>
            </div>
            {data?.coupon?.referrer && (
              <div className="flex items-center justify-between">
                <div className="text-sm text-gray-700">Referrer</div>
                <div className="font-medium text-gray-900 capitalize">
                  {data?.coupon?.referrer}
                </div>
              </div>
            )}
            {data?.coupon.redeemedAt && (
              <>
                <div className="flex items-center justify-between">
                  <div className="text-sm text-gray-700">Redeemed by</div>
                  <span className="font-medium text-gray-900">
                    {data?.coupon?.user ? (
                      <Link to={"/admin/member/" + data?.coupon?.user.id}>
                        {data?.coupon?.user.email}
                      </Link>
                    ) : (
                      "-"
                    )}
                  </span>
                </div>
                <div className="flex items-center justify-between">
                  <div className="text-sm text-gray-700">Redeemed at</div>
                  <span className="font-medium text-gray-900">
                    {data?.coupon.redeemedAt
                      ? format(
                          new Date(data?.coupon.redeemedAt),
                          "d LLL yyyy HH:mm"
                        )
                      : "-"}
                  </span>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default AdminCouponDetailsPage;
