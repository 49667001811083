import React from "react";

export const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full min-h-screen text-center">
      <h1 className="text-6xl font-semibold">404</h1>
      <h2 className="text-lg">Page Not Found</h2>
      <div className="py-2"></div>
      <div className="w-full border-b border-gray-300"></div>
      <div className="py-2"></div>
      <a
        href="/"
        className="flex items-center space-x-2 font-medium text-blue-600"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M7 16l-4-4m0 0l4-4m-4 4h18"
          />
        </svg>
        <span>Back to Dashboard</span>
      </a>
    </div>
  );
};
